import React, { useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import './Legal.scss';
import { Col, Drawer, Input, Layout, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import SideMenuLegal from '@components/side-menu-legal/SideMenuLegal';
import LegalWelcomeBlock from '@components/legal-welcome-block/LegalWelcomeBlock';
import { type LegalCategoryAttributes } from '@models/legal-documents.interface';
import { useGetLegalFilesQuery } from '@api/legal-files';
import { LEGAL_PREFIX } from '@constants/routers.const';
import { SearchOutlined } from '@ant-design/icons';
import LegalFile from '@components/legal-file/LegalFile';

const { Content, Sider } = Layout;

function Legal(): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams();
  const [search, setSearch] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string>('');
  const [currentDocks, setCurrentDocks] = useState<LegalCategoryAttributes | null>(null);
  const [open, setOpen] = useState(false);

  const { data, isLoading } = useGetLegalFilesQuery({ query: search });

  const currentCategoryId = useMemo(() => {
    if (id && data) {
      const category = data?.data.find(el =>
        el.attributes.legal_documents.data.find(doc =>
          doc?.attributes.files.find(file => {
            if (file.content.data?.attributes.hash === id) {
              setFileUrl(file.content.data.attributes.url);
              return true;
            }
            return false;
          }),
        ),
      );

      const categoryId = category?.id.toString() ?? '';

      setDefaultSelectedKeys(categoryId);

      return categoryId;
    }

    return '';
  }, [id, data]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value);
  };

  const showDrawer = (): void => {
    setOpen(true);
  };

  const onClose = (): void => {
    setOpen(false);
    setDefaultSelectedKeys(id ? currentCategoryId : '');
  };

  useEffect(() => {
    document.title = t('COMPONENT.NAVBAR.LEGAL');
  }, []);

  useEffect(() => {
    setCurrentDocks(
      !defaultSelectedKeys || !data
        ? null
        : data.data.find(el => el.id.toString() === defaultSelectedKeys)?.attributes ?? null,
    );

    if (!defaultSelectedKeys) onClose();
  }, [defaultSelectedKeys]);

  useEffect(() => {
    if (id && data) {
      const currentCategory = data?.data.find(el =>
        el.attributes.legal_documents.data.find(doc =>
          doc?.attributes.files.find(file => {
            if (file.content.data?.attributes.hash === id) {
              setFileUrl(file.content.data.attributes.url);
              return true;
            }
            return false;
          }),
        ),
      );
      setDefaultSelectedKeys(currentCategory?.id.toString() ?? '');
    }
  }, [id, data]);

  return (
    <div className='slow'>
      <Layout>
        <div>
          <Sider className='legal__sider' width={300} theme='light'>
            <SideMenuLegal
              showDrawer={showDrawer}
              defaultSelectedKeys={defaultSelectedKeys}
              setDefaultSelectedKeys={setDefaultSelectedKeys}
              data={data}
              isLoading={isLoading}
              input={
                <Input
                  className='side-search__input'
                  placeholder={t('GENERAL.SEARCH')}
                  prefix={<SearchOutlined />}
                  allowClear
                  value={search}
                  onChange={handleSearchChange}
                />
              }
            />
          </Sider>
        </div>
        <Content>
          <div className='flex flex-column legal__content' style={id ? { padding: 0 } : {}}>
            <Drawer placement='left' closable={false} onClose={onClose} open={open} getContainer={false}>
              <Row gutter={[48, 48]}>
                {currentDocks?.legal_documents.data.map(doc => {
                  return (
                    <Col span={12} key={doc?.attributes.title}>
                      <div className='flex flex-column legal__drawer'>
                        <div className='legal__drawerTitle'>{doc?.attributes.title}</div>
                        {doc?.attributes.files.map(file => {
                          return (
                            <Link
                              key={file.fileName}
                              onClick={onClose}
                              to={`${LEGAL_PREFIX}${file?.content.data?.attributes.hash ?? ``}`}
                              className='legal__file'>
                              {file.fileName}
                            </Link>
                          );
                        })}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Drawer>
            {!id ? <LegalWelcomeBlock /> : <LegalFile fileUrl={fileUrl} />}
            <Outlet />
          </div>
        </Content>
      </Layout>
    </div>
  );
}

export default Legal;
