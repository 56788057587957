import { Button, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import './SearchResultList.scss';
import { Link } from 'react-router-dom';
import { ROUTERS, WIKI_SEARCH_ID } from '@constants/routers.const';
import { ReactComponent as ArrowRightIcon } from '@icons/arrow-right.svg';
import { ReactComponent as CategoryIcon } from '@icons/category.svg';
import { ReactComponent as DocumentTextIcon } from '@icons/document-text.svg';
import { processUrlString } from '@utils/produse-url-string';
import { type WikiSection, type WikiSectionExtendedAttributes } from '@models/wiki-section.interface';
import { type ArticleShort } from '@models/article.interface';
import { useProductContext } from '@context/product/Product.context';

export default function SearchResultList({
  isWikisFetching,
  wikisData,
  articlesData,
  isArticlesFetching,
  search,
  setSearch,
  isAllResult = false,
}: {
  isWikisFetching: boolean;
  wikisData?: Array<WikiSection<WikiSectionExtendedAttributes>>;
  isArticlesFetching: boolean;
  articlesData?: ArticleShort[];
  search: string;
  setSearch?: (value: string) => void;
  isAllResult?: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  const { product } = useProductContext();

  return (
    <>
      {!isWikisFetching && !!wikisData?.length && (
        <div className='flex flex-column'>
          <div className={`flex flex-column search-result-list__container${!isAllResult ? '--small' : ''}`}>
            {wikisData.map(el => (
              <div className='flex search-result-list__row align-center' key={el.id}>
                <Icon className='search-result-list__icon' component={CategoryIcon} />
                <div className='flex flex-column search-result-list__content'>
                  <Link
                    to={processUrlString(ROUTERS.articles(product), { id: el.id })}
                    onClick={() => {
                      if (setSearch) setSearch('');
                    }}
                    className='search-result-list__link'>
                    {el.attributes.name}
                  </Link>
                  <div className='flex search-result-list__text'>
                    <div>{el.attributes?.parent?.data?.attributes.name ?? '-'}</div>
                    {el.attributes?.parent?.data?.attributes?.parent?.data?.attributes.name && (
                      <>
                        <img width={14} height={14} src='/images/icons/arrow-right.svg' alt='' />
                        <div>{el.attributes?.parent?.data?.attributes?.parent?.data?.attributes.name}</div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {!isArticlesFetching && !!articlesData?.length && (
        <div className='flex flex-column'>
          <div className={`flex flex-column search-result-list__container${!isAllResult ? '--small' : ''}`}>
            {articlesData.map(el => (
              <div className='flex search-result-list__row align-center' key={el.id}>
                <Icon className='search-result-list__icon' component={DocumentTextIcon} />
                <div className='flex flex-column search-result-list__content'>
                  <Link
                    to={processUrlString(ROUTERS.article(product), { id: el.attributes.slug })}
                    onClick={() => {
                      if (setSearch) setSearch('');
                    }}
                    className='search-result-list__link'>
                    {el.attributes.title}
                  </Link>
                  <div className='flex search-result-list__text'>
                    <div>{el.attributes?.wiki_section?.data?.attributes?.name ?? '-'}</div>
                    {el.attributes?.wiki_section?.data?.attributes.parent?.data?.attributes?.name && (
                      <>
                        <img width={14} height={14} src='/images/icons/arrow-right.svg' alt='' />
                        <div>{el.attributes.wiki_section?.data.attributes.parent?.data?.attributes.name}</div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {!isAllResult &&
        !isWikisFetching &&
        !isArticlesFetching &&
        (articlesData?.length ?? 0) + (wikisData?.length ?? 0) > 5 && (
          <Link
            to={`${ROUTERS.search(product)}?${WIKI_SEARCH_ID}=${search}`}
            onClick={() => {
              if (setSearch) setSearch('');
            }}>
            <Button type='link' className='search-result-list__all-button'>
              <div className='flex search-result-list__button-content'>
                {t('COMPONENT.SEARCH.ALL')}{' '}
                <Icon className='search-result-list__button-icon' component={ArrowRightIcon} />
              </div>
            </Button>
          </Link>
        )}
      {!isWikisFetching && !isArticlesFetching && !articlesData?.length && !wikisData?.length && (
        <div>{t('COMPONENT.SEARCH.NOTHING', { val: search })}</div>
      )}
      {(isWikisFetching || isArticlesFetching) && <Spin />}
    </>
  );
}
