// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-menu-legal .ant-menu-submenu-inline:not(.ant-menu-submenu-selected) > .ant-menu-submenu-title > .ant-menu-title-content a,
.side-menu-legal .ant-menu-submenu-vertical:not(.ant-menu-submenu-selected) > .ant-menu-submenu-title > .ant-menu-title-content a {
  color: black;
}
.side-menu-legal .ant-menu-item {
  white-space: normal;
  height: -moz-fit-content;
  height: fit-content;
}
.side-menu-legal .ant-menu-inline.ant-menu-root .ant-menu-item {
  align-items: flex-start;
}
.side-menu-legal .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  rotate: 180deg;
  top: 50%;
}
.side-menu-legal__item {
  gap: 12px;
}
.side-menu-legal__mainTitle {
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/side-menu-legal/SideMenuLegal.scss","webpack://./src/styles/constants.scss"],"names":[],"mappings":"AAMI;;EACE,YAAA;AAJN;AASE;EACE,mBAAA;EACA,wBAAA;EAAA,mBAAA;AAPJ;AAUE;EACE,uBAAA;AARJ;AAWE;EACE,cAAA;EACA,QAAA;AATJ;AAYE;EACE,SAAA;AAVJ;AAgBE;EACE,gBC9Be;ADgBnB","sourcesContent":["@import '@styles/colors.scss';\n@import '@styles/constants.scss';\n\n.side-menu-legal {\n  .ant-menu-submenu-inline:not(.ant-menu-submenu-selected)>.ant-menu-submenu-title>.ant-menu-title-content,\n  .ant-menu-submenu-vertical:not(.ant-menu-submenu-selected)>.ant-menu-submenu-title>.ant-menu-title-content {\n    a {\n      color: black;\n    }\n  }\n\n\n  .ant-menu-item {\n    white-space: normal;\n    height: fit-content;\n  }\n\n  .ant-menu-inline.ant-menu-root .ant-menu-item {\n    align-items: flex-start;\n  }\n\n  .ant-menu-submenu-open>.ant-menu-submenu-title>.ant-menu-submenu-arrow {\n    rotate: 180deg;\n    top: 50%;\n  }\n\n  &__item {\n    gap: calc($gap/2);\n  }\n\n  &__title {\n  }\n\n  &__mainTitle {\n    font-weight: $bold-font-weight;\n  }\n}\n\n","$gap: 24px;\n$gap-large: 32px;\n$padding: 64px;\n$padding-large: 100px;\n$bold-font-weight: 600;\n$font-weight: 400;\n$border-radius: 20px;\n$border-radius-medium: 30px;\n$border-radius-big: 40px;\n$break-point: 1439px;\n$mobile-break-point: 1023px;\n$mobile-navbar-height: 65px;\n$small-navbar-height: 95px;\n$navbar-height: 111px;\n$title-font-size: 32px;\n$mobile-title-font-size: 24px;\n$mobile-subtitle-font-size: 18px;\n$title-font-size-big: 42px;\n$box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);\n$subtitle-font-size-big: 18px;\n$subtitle-font-size: 16px;\n$text-font-size: 14px;\n$content-width: 1200px;\n$max-width: 1920px;\n$icon-font-size: 24px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
