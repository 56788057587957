import React from 'react';
import { useTranslation } from 'react-i18next';
import '../wiki-welcome-block/WikiWelcomeBlock.scss';

function LegalWelcomeBlock(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className='flex flex-column wiki-welcome-block'>
      <div className='wiki-welcome-block__title'>{t('COMPONENT.NAVBAR.LEGAL')}</div>
      <div className='wiki-welcome-block__sub-title' style={{ maxWidth: 722, lineHeight: '24px' }}>
        {t('COMPONENT.WELCOME_LEGAL_BLOCK.SUB_TITLE')}
      </div>
    </div>
  );
}

export default LegalWelcomeBlock;
