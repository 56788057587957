import React from 'react';
import { useTranslation } from 'react-i18next';
import './ZakromaDigital.scss';

const ITEMS = [
  {
    title: 'CONTAINERS.CLIENTS.OLD',
    info: '8',
  },
  {
    title: 'CONTAINERS.CLIENTS.EMPLOYEES',
    info: '250',
  },
  {
    title: 'CONTAINERS.CLIENTS.PROJECTS',
    info: '300+',
  },
];

export default function ZakromaDigital(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className='zakroma-digital flex flex-column'>
      <h1 className='zakroma-digital__title'>
        <span className='zakroma-digital__name'>
          <span className='zakroma-digital__title--color'>{t('CONTAINERS.CLIENTS.NAME')}</span>
        </span>
        <span>{t('CONTAINERS.CLIENTS.DESCRIPTION')}</span>
      </h1>
      <div className='flex zakroma-digital__info-area'>
        {ITEMS.map(item => (
          <div key={item.title} className='flex flex-column cell flex-0'>
            <h2 className='zakroma-digital__info'>{item.info}</h2>
            <p className='zakroma-digital__description'>{t(item.title)}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
