import { VIEW } from '@constants/layout.constants';
import { Col, Row, type TabsProps } from 'antd';
import React, { useEffect, useState } from 'react';
import './TasksTabs.scss';

export default function CustomTabs({ tabs, view }: { tabs: TabsProps['items']; view: VIEW }): JSX.Element {
  const [activeTab, setActiveTab] = useState(tabs?.[0]);

  useEffect(() => {
    setActiveTab(tabs?.[0]);
  }, [tabs]);

  return (
    <div className='flex flex-column tasks-tabs'>
      <div className='flex tasks-tabs__tabs-area'>
        {view === VIEW.PC ? (
          tabs?.map(tab => (
            <div
              className={`tasks-tabs__tab flex align-center ${activeTab && tab.key === activeTab.key ? 'tasks-tabs__tab--active' : ''}`}
              key={tab.key}
              onClick={() => {
                setActiveTab(tab);
              }}>
              {tab.label}
            </div>
          ))
        ) : (
          <Row gutter={[16, 16]} className='tasks-tabs__tab-menu'>
            <Col xs={24} sm={24} md={24} lg={12}>
              <div className='flex flex-column tasks-tabs__tab-menu-column'>
                {tabs
                  ?.filter((_tab, index) => index % 2 === 0)
                  .map(tab => (
                    <div
                      onClick={() => {
                        setActiveTab(tab);
                      }}
                      key={tab.key}
                      className={`tasks-tabs__menu-tab  ${activeTab && tab.key === activeTab.key ? 'tasks-tabs__menu-tab--active' : ''}`}>
                      <span>{tab.label}</span>
                    </div>
                  ))}
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <div className='flex flex-column tasks-tabs__tab-menu-column'>
                {tabs
                  ?.filter((_tab, index) => index % 2 !== 0)
                  .map(tab => (
                    <div
                      onClick={() => {
                        setActiveTab(tab);
                      }}
                      key={tab.key}
                      className={`tasks-tabs__menu-tab  ${activeTab && tab.key === activeTab.key ? 'tasks-tabs__menu-tab--active' : ''}`}>
                      <span>{tab.label}</span>
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
        )}
      </div>
      {activeTab?.children}
    </div>
  );
}
