import BecomePartner from '@components/become-partner/BecomePartner';
import ClientsList from '@components/clients-list/ClientsList';
import HomeFooter from '@components/home-footer/HomeFooter';
import ZakromaDigital from '@components/zakroma-digital/ZakromaDigital';
import { VIEW } from '@constants/layout.constants';
import { useViewMode } from '@hooks/use-window-size';
import React, { useEffect } from 'react';
import './Partners.scss';
import { useTranslation } from 'react-i18next';

const CLIENTS = [
  {
    img: '/images/partners/yandex-cloud.svg',
    alt: 'yandex-cloud',
  },
  {
    img: '/images/partners/deckhouse.png',
    alt: 'deckhouse',
  },
  {
    img: '/images/partners/yadro.png',
    alt: 'yadro',
  },
  {
    img: '/images/partners/basis.png',
    alt: 'basis',
  },
  {
    img: '/images/partners/astra-linux.png',
    alt: 'astra-linux',
  },
  {
    img: '/images/partners/flant.png',
    alt: 'flant',
  },
  {
    img: '/images/partners/rosa.svg',
    alt: 'rosa',
  },
  {
    img: '/images/partners/red-soft.png',
    alt: 'red-soft',
  },
];

export default function Partners(): JSX.Element {
  const { t } = useTranslation();
  const view = useViewMode();

  useEffect(() => {
    document.title = t('COMPONENT.NAVBAR.PARTNERS');
  }, []);

  return (
    <div className='flex flex-column'>
      <div className={`partners flex flex-column ${view === VIEW.PC ? 'partners__content' : ''}`}>
        <ZakromaDigital />
        <ClientsList items={CLIENTS} view={view} title='CONTAINERS.PARTNERS.KEY_PARTNERS' />
        <BecomePartner />
      </div>
      <div className='partners__footer'>
        <HomeFooter contactTitle='CONTAINERS.PARTNERS.FOOTER' view={view} />
      </div>
    </div>
  );
}
