export interface ImageData {
  link: string;
  width: string;
}

export const modifyArticleImage = (item: Nullable<string>): { content: string; images: ImageData[] } => {
  const reg = /(!\[.*\]\()(.*)( =)([0-9]*[.,]?[0-9]+%)(x\))/g;
  const content = item ?? '';
  const images = [];
  for (const match of Array.from(content.matchAll(reg))) {
    images.push({
      link: match[2],
      width: match[4],
    });
  }
  return { content: content.replaceAll(reg, '$1 $2)'), images };
};
