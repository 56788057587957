import React from 'react';
import './MobileNavigationMenu.scss';
import { NAV_BAR_CONFIG } from '@utils/get-menu-items';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function MobileNavigationMenu({
  parentCallback = () => {},
}: {
  parentCallback?: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = (link?: string): void => {
    if (link) {
      navigate(link);
      parentCallback();
    }
  };

  return (
    <div className='flex flex-column mobile-navigation-menu'>
      {NAV_BAR_CONFIG.map(el => (
        <div className='flex flex-column mobile-navigation-menu__item' key={el.key}>
          <div
            onClick={() => {
              handleClick(el.link);
            }}
            className={`mobile-navigation-menu__parent mobile-navigation-menu__menu-item ${el.link ? 'mobile-navigation-menu__link' : ''}`}>
            {t(el.label)}
          </div>
          {!!el.children?.length &&
            el.children.map(child => (
              <div
                className={`mobile-navigation-menu__menu-item ${child.link ? 'mobile-navigation-menu__link' : ''}`}
                key={child.key}
                onClick={() => {
                  handleClick(child.link);
                }}>
                {t(child.label)}
              </div>
            ))}
        </div>
      ))}
    </div>
  );
}
