import React, { useEffect } from 'react';
import './Contacts.scss';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useViewMode } from '@hooks/use-window-size';
import Footer from '@components/footer/Footer';
import ContactBlock from '@components/contact-block/ContactBlock';
import { VIEW } from '@constants/layout.constants';

export default function Contacts(): JSX.Element {
  const { t } = useTranslation();
  const view = useViewMode();

  useEffect(() => {
    document.title = t('COMPONENT.NAVBAR.CONTACTS');
  }, []);

  const contacts = [
    {
      title: 'CONTAINERS.CONTACTS.EMAIL',
      subTitle: 'GENERAL.EMAIL',
      img: 'images/email.svg',
      alt: 'email',
    },
    {
      title: 'CONTAINERS.CONTACTS.PHONE',
      subTitle: 'GENERAL.PHONE',
      img: 'images/phone.svg',
      alt: 'phone',
    },
    {
      title: 'CONTAINERS.CONTACTS.OFFICE',
      subTitle: 'GENERAL.ADDRESS',
      img: 'images/address.svg',
      alt: 'address',
    },
  ];

  return (
    <div className='flex flex-column contact--area'>
      <div className={`contact flex flex-column ${view === VIEW.PC ? 'contact__content' : ''}`}>
        <h1 className='contact__title cell flex-0'>{t('CONTAINERS.CONTACTS.TITLE')}</h1>
        <Row
          gutter={[
            { xs: 0, sm: 0, md: 0, lg: 24, xl: 32 },
            { xs: 16, sm: 16, md: 16, lg: 0, xl: 0 },
          ]}>
          {contacts.map(contact => (
            <Col xs={24} sm={24} md={24} lg={8} xl={8} key={contact.title}>
              <div className='flex align-center contact__cell'>
                <img className='contact__cell-img' src={contact.img} alt={contact.alt} />
                <div className='flex flex-column contact__cell-content'>
                  <div>
                    <span className='contact__cell-title'>{t(contact.title)}</span>
                  </div>
                  <div>
                    {contact.alt === 'email' ? (
                      <a className='contact__cell-subtitle' href='mailto:info@digital-spirit.ru'>
                        {t(contact.subTitle)}
                      </a>
                    ) : (
                      <span className='contact__cell-subtitle'>{t(contact.subTitle)}</span>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div className='contact__footer-area'>
        <div className='contact__form-and-photo'>
          <Row
            gutter={[
              { xs: 0, sm: 0, md: 0, lg: 24, xl: 32 },
              { xs: 32, sm: 32, md: 32, lg: 0, xl: 0 },
            ]}>
            <Col xs={24} sm={24} md={24} lg={14} xl={15}>
              <ContactBlock inputInRow={view !== VIEW.MOBILE ? 2 : 1} textAlign='start' view={view} smallVariant />
            </Col>
            <Col xs={24} sm={24} md={24} lg={10} xl={9}>
              <div className='contact__photo'></div>
            </Col>
          </Row>
        </div>
        <div className='contact__footer'>
          <Footer view={view} />
        </div>
      </div>
    </div>
  );
}
