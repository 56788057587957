import ClientsList from '@components/clients-list/ClientsList';
import HomeFooter from '@components/home-footer/HomeFooter';
import ZakromaDigital from '@components/zakroma-digital/ZakromaDigital';
import { useViewMode } from '@hooks/use-window-size';
import React, { useEffect } from 'react';
import './Clients.scss';
import { useTranslation } from 'react-i18next';
import { VIEW } from '@constants/layout.constants';

const CLIENTS = [
  {
    img: '/images/clients/alfa.svg',
    alt: 'alfa-bank',
  },
  {
    img: '/images/clients/ros-bank.svg',
    alt: 'ros-bank',
  },
  {
    img: '/images/clients/sber.svg',
    alt: 'sber-bank',
  },
  {
    img: '/images/clients/techno-nikol.png',
    alt: 'techno-nikol',
  },
  {
    img: '/images/clients/yamal.png',
    alt: 'yamal',
  },
  {
    img: '/images/clients/efko.png',
    alt: 'efko',
  },
  {
    img: '/images/clients/russian-bank.svg',
    alt: 'russian-bank',
  },
  {
    img: '/images/clients/gold-apple.png',
    alt: 'gold-apple',
  },
  {
    img: '/images/clients/letoile.svg',
    alt: 'letoile',
  },
  {
    img: '/images/clients/okb.png',
    alt: 'okb',
  },
  {
    img: '/images/clients/asaka.svg',
    alt: 'asaka-bank',
  },
  {
    img: '/images/clients/mf.svg',
    alt: 'megafon',
  },
  {
    img: '/images/clients/mgimo.svg',
    alt: 'mgimo',
  },
  {
    img: '/images/clients/nbu.svg',
    alt: 'nbu',
  },
  {
    img: '/images/clients/ural-bank.png',
    alt: 'ural-bank',
  },
  {
    img: '/images/clients/biccad.svg',
    alt: 'bissad',
  },
  {
    img: '/images/clients/pavlik.svg',
    alt: 'pavlik',
  },
  {
    img: '/images/clients/x5.svg',
    alt: 'x5',
  },
  {
    img: '/images/clients/beeline.svg',
    alt: 'beeline',
  },
  {
    img: '/images/clients/ria-news.svg',
    alt: 'ria-news',
  },
];

export default function Clients(): JSX.Element {
  const { t } = useTranslation();
  const view = useViewMode();

  useEffect(() => {
    document.title = t('COMPONENT.NAVBAR.CLIENTS');
  }, []);

  return (
    <div className='flex flex-column'>
      <div className={`clients flex flex-column  ${view === VIEW.PC ? 'clients__content' : ''}`}>
        <ZakromaDigital />
        <ClientsList items={CLIENTS} title='CONTAINERS.CLIENTS.TRUSTED' view={view} />
      </div>
      <div className='clients__footer'>
        <HomeFooter contactTitle='CONTAINERS.CLIENTS.BECAME_CLIENT' view={view} />
      </div>
    </div>
  );
}
