// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.storage-details {
  gap: 52px;
}
.storage-details__item {
  padding: 32px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  border-radius: 40px;
  text-align: center;
}

@media (max-width: 1439px) {
  .storage-details {
    gap: 24px;
  }
  .storage-details__item {
    font-size: 16px;
    padding: 24px;
  }
}
@media (max-width: 1023px) {
  .storage-details {
    flex-wrap: wrap;
  }
  .storage-details__item {
    min-width: calc(100% - 64px);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/storage-details/StorageDetails.scss","webpack://./src/styles/constants.scss"],"names":[],"mappings":"AAGA;EACE,SAAA;AAFF;AAIE;EACE,aCNQ;EDOR,kBAAA;EACA,eAAA;EACA,gBCNe;EDOf,mBCHgB;EDIhB,kBAAA;AAFJ;;AAMA;EACE;IACE,SClBE;EDeJ;EAKE;IACE,eCDe;IDEf,aCtBA;EDmBJ;AACF;AAOA;EACE;IACE,eAAA;EALF;EAOE;IACE,4BAAA;EALJ;AACF","sourcesContent":["@import '@styles/colors.scss';\n@import '@styles/constants.scss';\n\n.storage-details {\n  gap: 52px;\n\n  &__item {\n    padding: $gap-large;\n    text-align: center;\n    font-size: 20px;\n    font-weight: $bold-font-weight;\n    border-radius: $border-radius-big;\n    text-align: center;\n  }\n}\n\n@media (max-width: $break-point) {\n  .storage-details {\n    gap: $gap;\n\n    &__item {\n      font-size: $subtitle-font-size;\n      padding: $gap;\n    }\n  }\n}\n\n@media (max-width: $mobile-break-point) {\n  .storage-details {\n    flex-wrap: wrap;\n\n    &__item {\n      min-width: calc(100% - 64px);\n    }\n  }\n}","$gap: 24px;\n$gap-large: 32px;\n$padding: 64px;\n$padding-large: 100px;\n$bold-font-weight: 600;\n$font-weight: 400;\n$border-radius: 20px;\n$border-radius-medium: 30px;\n$border-radius-big: 40px;\n$break-point: 1439px;\n$mobile-break-point: 1023px;\n$mobile-navbar-height: 65px;\n$small-navbar-height: 95px;\n$navbar-height: 111px;\n$title-font-size: 32px;\n$mobile-title-font-size: 24px;\n$mobile-subtitle-font-size: 18px;\n$title-font-size-big: 42px;\n$box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);\n$subtitle-font-size-big: 18px;\n$subtitle-font-size: 16px;\n$text-font-size: 14px;\n$content-width: 1200px;\n$max-width: 1920px;\n$icon-font-size: 24px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
