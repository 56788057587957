import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tokenHeaderSetter } from '@utils/token-header-setter';
import { type DataMultiple } from '@models/data-multiple.interface';
import { upperFirst } from 'lodash';
import { type LegalCategory } from '@models/legal-documents.interface';

export const legalFilesApi = createApi({
  reducerPath: 'legalFilesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: tokenHeaderSetter,
  }),
  endpoints: builder => ({
    getLegalFiles: builder.query<DataMultiple<LegalCategory>, { query: string }>({
      query: ({ query }) =>
        `legal-categories?populate=legal_documents,legal_documents.files.content&filters[$or][0][name][$containsi]=${query}&filters[$or][1][name][$containsi]=${upperFirst(query)}`,
    }),
  }),
});

export const { useGetLegalFilesQuery } = legalFilesApi;
