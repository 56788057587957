import React, { type ReactElement, useEffect, useState } from 'react';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import './SideMenuLegal.scss';
import { useLocation, Link } from 'react-router-dom';
import { type LegalCategoryAttributes, type LegalCategory } from '@models/legal-documents.interface';
import { ROUTERS } from '@constants/routers.const';
import { useTranslation } from 'react-i18next';
import { type DataMultiple } from '@models/data-multiple.interface';

type MenuItem = Required<MenuProps>['items'][number];

interface SideMenuLegalProps {
  showDrawer: () => void;
  defaultSelectedKeys: string;
  setDefaultSelectedKeys: (value: string) => void;
  isLoading: boolean;
  data: DataMultiple<LegalCategory<LegalCategoryAttributes>> | undefined;
  input: ReactElement;
}

function SideMenuLegal({
  showDrawer,
  defaultSelectedKeys,
  setDefaultSelectedKeys,
  data,
  isLoading,
  input,
}: SideMenuLegalProps): JSX.Element {
  const { t } = useTranslation();

  const [menuItems, setMenuItems] = useState<MenuProps['items']>([]);

  const { pathname } = useLocation();

  const getMenuItems = (items: LegalCategory[]): { items: MenuProps['items']; keys: string[] } => {
    const keys: string[] = [];
    function getMenuItem(item: LegalCategory): MenuItem {
      const key = item.id.toString();
      return {
        label: (
          <>
            <div
              className='flex align-center space-between side-menu-legal__item'
              onClick={() => {
                setDefaultSelectedKeys(key);
                showDrawer();
              }}>
              <div className='side-menu-legal__title'>{item.attributes.name}</div>

              <img src='/images/icons/arrow-right-bold.svg' alt='link' />
            </div>
          </>
        ),
        key,
      };
    }

    const menuData = items.map(i => getMenuItem(i));
    return {
      items: menuData,
      keys,
    };
  };

  useEffect(() => {
    const info = getMenuItems(data?.data.filter(el => !!el.attributes.legal_documents.data.length) ?? []);

    const legal: MenuItem = {
      key: '',
      label: (
        <Link
          to={ROUTERS.legal}
          onClick={() => {
            setDefaultSelectedKeys('');
          }}
          className='side-menu-legal__mainTitle'>
          {t('CONTAINERS.HOME.NAME')}
        </Link>
      ),
    };

    setMenuItems([legal, ...(info.items ?? [])]);
  }, [data, pathname]);

  return !isLoading && menuItems ? (
    <div className='side-menu-legal'>
      {input}
      <Menu
        items={menuItems}
        mode='inline'
        onSelect={selected => {
          setDefaultSelectedKeys(selected.key);
        }}
        selectedKeys={[defaultSelectedKeys]}
      />
    </div>
  ) : (
    <></>
  );
}

export default SideMenuLegal;
