import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import './Main.scss';
import { Col, Row } from 'antd';
import ScrollToTop from '@components/scroll-to-top/ScrollToTop';
import { useTranslation } from 'react-i18next';
import NavBar from '@components/nav-bar/NavBar';
import { ROUTERS } from '@constants/routers.const';

function Main(): JSX.Element {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const mobileViewPaths = [ROUTERS.home, ROUTERS.storage, ROUTERS.archive, ROUTERS.contacts];
  const [isMobileViewAvailiable, setIsMobileViewAvailiable] = useState<boolean>(mobileViewPaths.includes(pathname));
  useEffect(() => {
    setIsMobileViewAvailiable(mobileViewPaths.includes(pathname));
  }, [pathname]);

  useEffect(() => {
    document.title = t('GENERAL.TITLE');
  }, []);
  return (
    <div className='main'>
      <Row className={`main__content ${isMobileViewAvailiable ? 'main__mobile' : ''}`}>
        <Col span={24}>
          <ScrollToTop />
          <NavBar isMobileViewAvailiable={isMobileViewAvailiable} />
          <Outlet />
        </Col>
      </Row>
    </div>
  );
}

export default Main;
