import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { openNotification } from '@utils/open-notification';
import { t } from 'i18next';

export const contactApi = createApi({
  reducerPath: 'contactApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_CONTACT_URL,
  }),
  endpoints: builder => ({
    sendContactForm: builder.mutation<unknown, FormData>({
      query: (data: FormData) => ({
        url: '',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        formData: true,
      }),
      async onQueryStarted(_arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
          openNotification('success', t('CONTAINERS.HOME.CONTACT.MESSAGES.SUCCESS'));
        } catch (err) {
          openNotification('error', t('CONTAINERS.HOME.CONTACT.MESSAGES.ERROR'));
        }
      },
    }),
  }),
});

export const { useSendContactFormMutation } = contactApi;
