import React, { useState } from 'react';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { from } from 'rxjs';

function BlogShareBlock(): JSX.Element {
  const { t } = useTranslation();

  const [copyOk, setCopyOk] = useState(false);

  const handleClick = (): void => {
    from(navigator.clipboard.writeText(location.origin + location.pathname)).subscribe(() => {
      setCopyOk(true);
      setTimeout(() => {
        setCopyOk(false);
      }, 500);
    });
  };

  return (
    <Dropdown
      dropdownRender={() => (
        <div className='blog-article__share__dropdown flex align-center' onClick={handleClick}>
          <img src='/images/icons/link-2.svg' alt='link' />
          {copyOk ? 'Copied!' : 'Copy link'}
        </div>
      )}>
      <div className='blog-article__share flex space-between align-center'>
        {t('GENERAL.SHARE')} <img src='/images/icons/share.svg' alt='share' />
      </div>
    </Dropdown>
  );
}

export default BlogShareBlock;
