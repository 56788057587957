import { BlockquoteType, BlockquoteTypeId } from '@models/blockquote-type.enum';
import { type ReactNode } from 'react';

export const generateBlockquoteId = (children: ReactNode[]): { id: string; children: any } => {
  let id = BlockquoteTypeId.INFO;
  const ids = new Set<BlockquoteTypeId>();
  const getTypeCleanText = (children: any[]): void => {
    for (let index = 0; index < children?.length; index++) {
      const child = children[index];
      if (typeof child === 'string') {
        const id = getIdByText(child);
        ids.add(id);
        children[index] = replaceBlockQuoteType(child);
      } else if (typeof child === 'object') {
        if (child.props.id) {
          ids.add(child.props.id);
          break;
        }
        getTypeCleanText(child.props.children);
      }
    }
  };
  getTypeCleanText(children);
  switch (true) {
    case ids.has(BlockquoteTypeId.DANGER):
      id = BlockquoteTypeId.DANGER;
      break;
    case ids.has(BlockquoteTypeId.SUCCESS):
      id = BlockquoteTypeId.SUCCESS;
      break;
    case ids.has(BlockquoteTypeId.WARNING):
      id = BlockquoteTypeId.WARNING;
      break;
  }
  return { id, children };
};

export const getIdByText = (text: string): BlockquoteTypeId => {
  switch (true) {
    case text.includes(BlockquoteType.DANGER):
      return BlockquoteTypeId.DANGER;
    case text.includes(BlockquoteType.SUCCESS):
      return BlockquoteTypeId.SUCCESS;
    case text.includes(BlockquoteType.WARNING):
      return BlockquoteTypeId.WARNING;
    default:
      return BlockquoteTypeId.INFO;
  }
};

export const replaceBlockQuoteType = (text: string): string =>
  text
    .replace(BlockquoteType.DANGER, '')
    .replace(BlockquoteType.SUCCESS, '')
    .replace(BlockquoteType.WARNING, '')
    .replace(BlockquoteType.INFO, '');
