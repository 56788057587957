import React from 'react';
import './ReadUsButton.scss';
import Icon from '@ant-design/icons';
import { ZAKROMA_TELEGRAM } from '@constants/other.const';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TelegramIcon } from '@icons/telegram.svg';

export default function ReadUsButton({ dark = false }: { dark?: boolean }): JSX.Element {
  const { t } = useTranslation();
  return (
    <Button
      onClick={() => window.open(ZAKROMA_TELEGRAM, '_blank')}
      className={`read-us-button ${dark ? 'read-us-button--dark' : 'read-us-button--light'}`}>
      <div className='flex read-us-button__content'>
        <div>
          <span>{t('COMPONENT.NAVBAR.READ_US')}</span>
        </div>
        <Icon component={TelegramIcon} />
      </div>
    </Button>
  );
}
