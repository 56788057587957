import { useGetArticlesByWikiIdQuery, useGetStartArticlesQuery } from '@api/articles';
import ArticlesList from '@components/articles-list/ArticlesList';
import { WIKI_START_ID } from '@constants/routers.const';
import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import './WikiArticles.scss';
import { useGetWikiSectionByIdQuery } from '@api/wiki-sektions';
import MDContent from '@components/md-content/MDContent';
import { useProductContext } from '@context/product/Product.context';
import { PRODUCTS_WIKI_ID } from '@constants/products.const';

function WikiArticles(): JSX.Element {
  const { id } = useParams();
  const { product } = useProductContext();
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getArticleById = (id: string) => {
    switch (id) {
      case WIKI_START_ID:
        return useGetStartArticlesQuery(WIKI_START_ID);
      case '':
        return useGetArticlesByWikiIdQuery(PRODUCTS_WIKI_ID[product]);
      default:
        return useGetArticlesByWikiIdQuery(id);
    }
  };
  const { data, isFetching } = getArticleById(id ?? '');

  const { data: wikiData } = useGetWikiSectionByIdQuery(id ?? PRODUCTS_WIKI_ID[product], {
    skip: id === WIKI_START_ID,
  });

  useEffect(() => {
    if (wikiData) {
      document.title = wikiData.data.attributes.name;
    }
  }, [data]);

  if (isFetching) {
    return <Spin />;
  }

  return data ? (
    <div className='wiki-articles flex flex-column'>
      {wikiData?.data.attributes.name && !id && (
        <div className='flex flex-column wiki-articles__title'>
          <h1 className='wiki-articles__name'>{wikiData.data.attributes.name}</h1>
        </div>
      )}
      {wikiData?.data.attributes.content ? (
        <MDContent content={wikiData.data.attributes.content} images={wikiData.data.attributes.images} />
      ) : (
        <></>
      )}
      <ArticlesList items={data.data} />
    </div>
  ) : (
    <></>
  );
}

export default WikiArticles;
