import React, { useState } from 'react';
import './ArticleTableOfContent.scss';
import { HashLink as Link } from 'react-router-hash-link';
import { type AnchorLinkItemProps } from 'antd/es/anchor/Anchor';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

export default function ArticleTableOfContent({
  items,
  offset,
  maxElementsNum,
}: {
  items: AnchorLinkItemProps[];
  offset: number;
  maxElementsNum?: number;
}): JSX.Element {
  const { t } = useTranslation();
  const scrollWidthOffset = (el: Element): void => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: yCoordinate - offset, behavior: 'smooth' });
  };
  let totalCount = 0;
  items.forEach(item => {
    totalCount++;
    totalCount = totalCount + (item.children?.length ?? 0);
  });
  const [collapsed, setCollapsed] = useState(true);
  let itemsToShow: AnchorLinkItemProps[] = [];
  if (!maxElementsNum || maxElementsNum >= totalCount || !collapsed) {
    itemsToShow = items;
  } else {
    let count = 0;
    for (let i = 0; i < items.length; i++) {
      const l = items[i];
      itemsToShow.push({ key: l.key, href: l.href, title: l.title, children: [] });
      count++;
      if (count === maxElementsNum) {
        break;
      }
      if (l.children) {
        l.children.forEach(child => {
          if (count < maxElementsNum) {
            (itemsToShow[itemsToShow.length - 1].children as AnchorLinkItemProps[]).push(child);
            count++;
          }
        });
        if (count === maxElementsNum) {
          break;
        }
      }
    }
  }

  return items ? (
    <div className='flex flex-column article-table-of-content'>
      <div className='article-table-of-content__title'>{t('CONTAINERS.ARTICLE.CONTENT')}</div>
      {itemsToShow.map(item => (
        <div key={item.key} className='flex flex-column article-table-of-content__parents'>
          <Link
            to={item.href}
            scroll={el => {
              scrollWidthOffset(el);
            }}
            className='article-table-of-content__item'>
            {item.title}
          </Link>
          {item.children?.length ? (
            <div className='flex flex-column article-table-of-content__children'>
              {item.children.map(el => (
                <Link
                  key={el.key}
                  to={el.href}
                  scroll={el => {
                    scrollWidthOffset(el);
                  }}
                  className='article-table-of-content__item'>
                  {el.title}
                </Link>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      ))}
      {maxElementsNum && totalCount > maxElementsNum ? (
        <Button
          className='article-table-of-content__button'
          type='link'
          onClick={() => {
            setCollapsed(!collapsed);
          }}>
          {!collapsed ? t('GENERAL.LESS') : t('GENERAL.MORE')}
        </Button>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
}
