import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tokenHeaderSetter } from '@utils/token-header-setter';
import { type ArticleShort } from '@models/article.interface';
import { type DataMultiple } from '@models/data-multiple.interface';
import { upperFirst } from 'lodash';

export const articlesApi = createApi({
  reducerPath: 'articlesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: tokenHeaderSetter,
  }),
  endpoints: builder => ({
    getArticlesByWikiId: builder.query<DataMultiple<ArticleShort>, string>({
      query: wikiId => `articles?filters[wiki_section][id][$in]=${wikiId}`,
      transformResponse: (response: DataMultiple<ArticleShort>) => {
        response.data.sort((a, b) => a.attributes.order - b.attributes.order);
        return response;
      },
    }),
    getStartArticles: builder.query<DataMultiple<ArticleShort>, string>({
      query: () => 'articles?filters[showOnStartPage][$eq]=true',
    }),
    getArticlesBySearch: builder.query<
      DataMultiple<ArticleShort>,
      { search: string; isShort: boolean; product: string }
    >({
      query: ({ search, isShort, product }) =>
        `articles?filters[$or][0][title][$containsi]=${search}&filters[$or][1][title][$containsi]=${upperFirst(search)}&populate=wiki_section,wiki_section.parent${isShort ? '&pagination[start]=0&pagination[limit]=5' : ''}&filters[product][slug][$eq]=${product}`,
    }),
  }),
});

export const { useGetArticlesByWikiIdQuery, useGetStartArticlesQuery, useGetArticlesBySearchQuery } = articlesApi;
