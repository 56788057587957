import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ArticleVectorSelector.scss';

export default function ArticleVectorSelector({
  versions,
  onSelectVersion,
}: {
  versions: string[];
  onSelectVersion: (value: string) => void;
}): JSX.Element {
  const { t } = useTranslation();
  const [items, setItems] = useState<string[]>([]);
  const [currentItem, setCurrentItem] = useState<Nullable<string>>(null);

  useEffect(() => {
    setItems(versions);
    setCurrentItem(versions[0]);
  }, [versions]);

  return (
    <div className='flex flex-column article-version-selector'>
      <div className=''>{t('COMPONENT.VERSION_SELECTOR.TITLE')}</div>
      <Select
        key={currentItem}
        value={currentItem}
        onChange={value => {
          setCurrentItem(value);
          onSelectVersion(value);
        }}
        className='article-version-selector__selector'
        options={items.map(version => ({ value: version, label: version }))}
      />
    </div>
  );
}
