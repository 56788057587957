import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './StorageAdvantages.scss';

export default function StorageAdvantages({ items }: { items: Array<{ title: string; icon: string }> }): JSX.Element {
  const { t } = useTranslation();

  return (
    <Row
      className='storage-advantages'
      gutter={[
        { xs: 12, sm: 12, md: 12, lg: 24, xl: 32 },
        { xs: 12, sm: 12, md: 12, lg: 24, xl: 32 },
      ]}>
      {items?.map(advantage => (
        <Col key={advantage.title} xs={24} sm={24} md={24} lg={12} xl={8}>
          <p className='storage-advantages__item flex align-center'>
            <img src={advantage.icon} alt='icon' className='storage-advantages__item-image' />
            <span>{t(advantage.title)}</span>
          </p>
        </Col>
      ))}
    </Row>
  );
}
