import React, { useEffect } from 'react';
import './ZakromaStorage.scss';
import { useGetStoragePageQuery } from '@api/pages';
import { Spin } from 'antd';
import StorageFeatures from '@components/storage-features/StorageFeatures';
import { PRIMARY_COLOR, VIEW } from '@constants/layout.constants';
import { useViewMode } from '@hooks/use-window-size';
import HomeFooter from '@components/home-footer/HomeFooter';
import StorageTasks from '@components/storage-tasks/StorageTasks';
import StorageDetails from '@components/storage-details/StorageDetails';
import StorageAdvantages from '@components/storage-advantages/StorageAdvantages';
import StorageMain from '@components/storage-main/StorageMain';
import StorageDescription from '@components/storage-description/StorageDescription';
import { useTranslation } from 'react-i18next';

const STORAGE_ADVANTAGES = [
  {
    title: 'CONTAINERS.HOME.ECOSYSTEM.STORAGE.MEMORY',
    icon: '/images/storage.svg',
  },
  {
    title: 'CONTAINERS.HOME.ECOSYSTEM.STORAGE.POWER',
    icon: '/images/options.svg',
  },
  {
    title: 'CONTAINERS.HOME.ECOSYSTEM.STORAGE.INTEGRATION',
    icon: '/images/check.svg',
  },
  {
    title: 'CONTAINERS.HOME.ECOSYSTEM.STORAGE.SCALABILITY',
    icon: '/images/notebook.svg',
  },
  {
    title: 'CONTAINERS.HOME.ECOSYSTEM.STORAGE.PERFORMANCE',
    icon: '/images/archive.svg',
  },
  {
    title: 'CONTAINERS.HOME.ECOSYSTEM.STORAGE.ARCHITECTURE',
    icon: '/images/time.svg',
  },
];

const DESCRIPTION_FOR_SMALL_SCREEN = [
  {
    id: 1,
    size: 12,
  },
  {
    id: 2,
    size: 12,
  },
  {
    id: 6,
    size: 10,
  },
  {
    id: 'parent-1',
    size: 14,
    children: [
      {
        id: 4,
        size: 24,
      },
      {
        id: 5,
        size: 24,
      },
    ],
  },
];

const DESCRIPTION_FOR_BIG_SCREEN = [
  {
    id: 1,
    size: 8,
  },
  {
    id: 2,
    size: 10,
  },
  {
    id: 3,
    size: 6,
  },
  {
    id: 4,
    size: 7,
  },
  {
    id: 5,
    size: 7,
  },
  {
    id: 6,
    size: 10,
  },
];

const DESCRIPTION_FOR_MOBILE_SCREEN = [
  {
    id: 1,
    size: 24,
  },
  {
    id: 2,
    size: 24,
  },
  {
    id: 6,
    size: 24,
  },
  {
    id: 4,
    size: 24,
  },
  {
    id: 5,
    size: 24,
  },
];

export default function ZakromaStorage(): JSX.Element {
  const { t } = useTranslation();
  const { data, isFetching } = useGetStoragePageQuery('');
  const view = useViewMode();

  useEffect(() => {
    document.title = t('COMPONENT.NAVBAR.STORAGE');
  }, []);

  return (
    <div className='flex flex-column'>
      {isFetching ? <Spin /> : <></>}
      {!isFetching && data && (
        <>
          <div className={`zakroma-storage flex flex-column ${view === VIEW.PC ? 'zakroma-storage__content' : ''}`}>
            <StorageMain
              view={view}
              title='CONTAINERS.HOME.ECOSYSTEM.STORAGE.TITLE'
              description='CONTAINERS.HOME.ECOSYSTEM.STORAGE.DESCRIPTION'
              color={PRIMARY_COLOR}
              iconPath='/images/data-migration.svg'
              imagePath='/images/zakroma-storage.svg'
            />
            <StorageAdvantages items={STORAGE_ADVANTAGES} />
            <div className='zakroma-storage__description'>
              {view !== VIEW.MOBILE && (
                <>
                  <div className='zakroma-storage__system zakroma-storage__icon'></div>
                  <div className='zakroma-storage__analytics zakroma-storage__icon'></div>
                  <div className='zakroma-storage__hierarchy zakroma-storage__icon'></div>
                </>
              )}
              <StorageDescription
                view={view}
                mobileConfig={DESCRIPTION_FOR_MOBILE_SCREEN}
                tabletConfig={DESCRIPTION_FOR_SMALL_SCREEN}
                config={DESCRIPTION_FOR_BIG_SCREEN}
                prefix='CONTAINERS.PRODUCT.STORAGE.DESCRIPTION'
              />
            </div>
            <StorageTasks block={data.data.attributes.solvedTasks} view={view} />
            <StorageFeatures block={data.data.attributes.productFeatures} view={view} />
            <StorageDetails />
          </div>
          <div className='zakroma-storage__footer'>
            <HomeFooter view={view} />
          </div>
        </>
      )}
    </div>
  );
}
