import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tokenHeaderSetter } from '@utils/token-header-setter';
import { type ArticleData } from '@models/article.interface';
import { modifyArticleLink } from '@utils/markdown/modify-article-link';
import { modifyArticleImage } from '@utils/markdown/modify-article-image';
import dayjs from 'dayjs';

export const articleApi = createApi({
  reducerPath: 'articleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: tokenHeaderSetter,
  }),
  endpoints: builder => ({
    getArticleBySlug: builder.query<ArticleData, string>({
      query: slug =>
        `articles?populate[0]=wiki_section.parent&populate[1]=references&populate[2]=blocks&filters[$or][0][slug][$eq]=${slug}&filters[$or][1][name][$eq]=${slug}`,
      transformResponse: (response: ArticleData) => {
        response.data[0]?.attributes?.blocks.forEach(block => {
          let content = block?.body;
          if (content) {
            content = modifyArticleLink(content);
            const resultWithImage = modifyArticleImage(content);
            content = resultWithImage.content;
            Object.assign(block, { images: resultWithImage.images });
            block.body = `#### Обновлена ${dayjs(response.data[0]?.attributes?.updatedAt).locale('ru').format('DD MMMM YYYY')} г.\n ${content}`;
          }
        });
        return response;
      },
    }),
  }),
});

export const { useGetArticleBySlugQuery } = articleApi;
