import StorageDescriptionItem from '@components/storage-description-item/StorageDescriptionItem';
import { VIEW } from '@constants/layout.constants';
import { type DescriptionItem } from '@models/storage-description-item.interface';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './StorageDescription.scss';

export default function StorageDescription({
  view,
  tabletConfig,
  mobileConfig,
  config,
  prefix,
}: {
  view: VIEW;
  tabletConfig: DescriptionItem[];
  mobileConfig: DescriptionItem[];
  config: DescriptionItem[];
  prefix: string;
}): JSX.Element {
  const { t } = useTranslation();
  const [items, setItems] = useState<DescriptionItem[]>([]);
  useEffect(() => {
    switch (view) {
      case VIEW.PC:
        setItems(config);
        break;
      case VIEW.TABLET:
        setItems(tabletConfig);
        break;
      case VIEW.MOBILE:
        setItems(mobileConfig);
        break;
    }
  }, [view]);

  return (
    <div className='flex flex-column storage-description'>
      <h2 className='storage-description__title'>{t(`${prefix}.TITLE`)}</h2>
      <Row gutter={[24, 24]} align='stretch'>
        {items.map(item => {
          if (!item.children?.length) {
            return <StorageDescriptionItem key={item.id} item={item} prefix={prefix} />;
          } else {
            return (
              <Col className='storage-description__col' key={item.id} span={item.size}>
                <Row gutter={[24, 24]}>
                  {item.children.map(child => (
                    <StorageDescriptionItem key={child.id} item={child} prefix={prefix} />
                  ))}
                </Row>
              </Col>
            );
          }
        })}
      </Row>
    </div>
  );
}
