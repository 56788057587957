import React, { useState } from 'react';
import './NavBar.scss';
import { Menu, Divider, Button } from 'antd';
import type { MenuProps } from 'antd';
import { ROUTERS } from '@constants/routers.const';
import { useNavigate } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { getMenuItems } from '@utils/get-menu-items';
import { useViewMode } from '@hooks/use-window-size';
import { VIEW } from '@constants/layout.constants';
import { ReactComponent as MenuIcon } from '@icons/menu.svg';
import { ReactComponent as CloseMenuIcon } from '@icons/close.svg';
import MobileNavigationMenu from '@components/mobile-navigation-menu/MobileNavigationMenu';
import ReadUsButton from '@components/read-us-button/ReadUsButton';

function NavBar({ isMobileViewAvailiable }: { isMobileViewAvailiable: boolean }): JSX.Element {
  const navigate = useNavigate();
  const [menuItems] = useState<MenuProps['items']>(getMenuItems());
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const view = useViewMode();
  const handleLogoClick = (): void => {
    navigate(ROUTERS.home);
  };

  return (
    <div className={`nav-bar ${mobileMenuOpen ? 'nav-bar--open' : ''}`}>
      <div className='nav-bar__content flex space-between align-center flex-wrap'>
        <div className='cell flex-0'>
          <img
            onClick={handleLogoClick}
            src={`/images/${mobileMenuOpen ? 'white-logo.svg' : 'logo.svg'}`}
            alt='logo'
            className='nav-bar__logo'
          />
        </div>
        {!(view === VIEW.MOBILE && isMobileViewAvailiable) ? (
          <>
            <Menu mode={'horizontal'} items={menuItems} className='nav-bar__menu' />
            <div className='cell flex-0'>
              <ReadUsButton />
            </div>
          </>
        ) : (
          <>
            <Button
              type={mobileMenuOpen ? 'primary' : 'text'}
              className='nav-bar__button'
              onClick={() => {
                setMobileMenuOpen(!mobileMenuOpen);
              }}>
              <Icon className='nav-bar__button-icon' component={mobileMenuOpen ? CloseMenuIcon : MenuIcon} />
            </Button>
            {mobileMenuOpen && (
              <div className='nav-bar__mobile-menu'>
                <MobileNavigationMenu
                  parentCallback={() => {
                    setMobileMenuOpen(!mobileMenuOpen);
                  }}
                />
                <div className='nav-bar__read-us'>
                  <ReadUsButton dark={true} />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <Divider className='nav-bar__divider' />
    </div>
  );
}

export default NavBar;
