import React, { useEffect } from 'react';
import './KnowledgeBase.scss';
import { useTranslation } from 'react-i18next';
import { PRODUCTS_CONTENT } from '@constants/products.const';
import WikiWelcomeBlock from '@components/wiki-welcome-block/WikiWelcomeBlock';
import { Col, Layout, Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import ProductsListCart from '@components/products-list-cart/ProductsListCart';

function KnowledgeBase(): JSX.Element {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('COMPONENT.NAVBAR.DOCS');
  }, []);

  return (
    <div className='slow knowledge-base'>
      <Layout>
        <Content>
          <div className='flex flex-column knowledge-base__content'>
            <WikiWelcomeBlock />
            <Row gutter={[32, 32]}>
              {PRODUCTS_CONTENT.map(product => (
                <Col span={12} key={product.path}>
                  <ProductsListCart product={product} />
                </Col>
              ))}
            </Row>
          </div>
        </Content>
      </Layout>
    </div>
  );
}

export default KnowledgeBase;
