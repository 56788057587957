import { configureStore } from '@reduxjs/toolkit';
import { articleApi } from '@api/article';
import { createReducerArgs } from './reducers';
import { wikiSectionsApi } from '@api/wiki-sektions';
import { articlesApi } from '@api/articles';
import { blogArticlesApi } from '@api/blog-articles';
import { blogArticleApi } from '@api/blog-article';
import { blogPageApi } from '@api/blog-page';
import { blogCategoriesApi } from '@api/blog-categories';
import { pagesApi } from '@api/pages';
import { contactApi } from '@api/contacts';
import { blogSubscriptionApi } from '@api/blog-subscription';
import { legalFilesApi } from '@api/legal-files';

export const configureStoreZakroma = (): any =>
  configureStore({
    reducer: createReducerArgs(),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(
        articleApi.middleware,
        articlesApi.middleware,
        wikiSectionsApi.middleware,
        blogArticlesApi.middleware,
        blogArticleApi.middleware,
        blogPageApi.middleware,
        blogCategoriesApi.middleware,
        pagesApi.middleware,
        contactApi.middleware,
        blogSubscriptionApi.middleware,
        legalFilesApi.middleware,
      ),
  });
