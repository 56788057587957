import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Row, Col, Button } from 'antd';
import './ContactBlock.scss';
import { useLocation } from 'react-router-dom';
import { type ContactData } from '@models/contact-data.interface';
import { useSendContactFormMutation } from '@api/contacts';
import { VIEW } from '@constants/layout.constants';

export default function ContactBlock({
  inputInRow,
  textAlign,
  view,
  title,
  smallVariant = false,
}: {
  inputInRow?: number;
  textAlign?: 'start';
  view: VIEW;
  title?: string;
  smallVariant?: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { pathname } = useLocation();
  const [sendContact] = useSendContactFormMutation();

  const onFinish = (values: ContactData): void => {
    const bodyFormData = new FormData();
    const page = pathname.replace('/', '');
    bodyFormData.append('page', page || 'home');
    Object.entries(values)
      .filter(([_key, value]) => value)
      .forEach(([key, value]) => {
        bodyFormData.append(key, value);
      });
    void sendContact(bodyFormData);
  };

  const validateMessages = {
    required: t('GENERAL.VALIDATION.REQUIRED'),
    types: {
      email: t('GENERAL.VALIDATION.EMAIL'),
    },
  };

  return (
    <div className={`flex flex-column contact-block ${smallVariant ? 'contact-block--small' : ''}`}>
      <div className='flex flex-column contact-block__text-area' style={{ textAlign: textAlign ?? 'center' }}>
        <h1 className='contact-block__title'>{t(title ?? 'CONTAINERS.HOME.CONTACT.TITLE')}</h1>
        <div className='contact-block__description'>
          <p>{t('CONTAINERS.HOME.CONTACT.DESCRIPTION')}</p>
          <p>{t('CONTAINERS.HOME.CONTACT.DESCRIPTION_1')}</p>
        </div>
      </div>
      <Form
        name='basic'
        initialValues={{ name: '' }}
        onFinish={onFinish}
        autoComplete='off'
        validateMessages={validateMessages}
        form={form}>
        {!(inputInRow ?? view === VIEW.MOBILE) ? (
          <div className='flex contact-block__form-area'>
            <Form.Item<ContactData> name='name' rules={[{ required: true }]}>
              <Input className='contact-block__input' placeholder={t('CONTAINERS.HOME.CONTACT.NAME')} />
            </Form.Item>
            <Form.Item<ContactData> name='email' rules={[{ required: true }, { type: 'email' }]}>
              <Input className='contact-block__input' placeholder={t('CONTAINERS.HOME.CONTACT.EMAIL')} />
            </Form.Item>
            <Form.Item<ContactData> name='organization'>
              <Input className='contact-block__input' placeholder={t('CONTAINERS.HOME.CONTACT.ORGANIZATION')} />
            </Form.Item>
            <Form.Item<ContactData> name='phone'>
              <Input className='contact-block__input' placeholder={t('CONTAINERS.HOME.CONTACT.PHONE')} />
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit' className='contact-block__button'>
                {t('CONTAINERS.HOME.CONTACT.SEND')}
              </Button>
            </Form.Item>
          </div>
        ) : (
          <>
            <Row gutter={[16, 16]}>
              <Col span={24 / (inputInRow ?? 1)}>
                <Form.Item<ContactData> name='name' rules={[{ required: true }]}>
                  <Input className='contact-block__input' placeholder={t('CONTAINERS.HOME.CONTACT.NAME')} />
                </Form.Item>
              </Col>
              <Col span={24 / (inputInRow ?? 1)}>
                <Form.Item<ContactData> name='email' rules={[{ required: true }, { type: 'email' }]}>
                  <Input className='contact-block__input' placeholder={t('CONTAINERS.HOME.CONTACT.EMAIL')} />
                </Form.Item>
              </Col>
              <Col span={24 / (inputInRow ?? 1)}>
                <Form.Item<ContactData> name='organization'>
                  <Input className='contact-block__input' placeholder={t('CONTAINERS.HOME.CONTACT.ORGANIZATION')} />
                </Form.Item>
              </Col>
              <Col span={24 / (inputInRow ?? 1)}>
                <Form.Item<ContactData> name='phone'>
                  <Input className='contact-block__input' placeholder={t('CONTAINERS.HOME.CONTACT.PHONE')} />
                </Form.Item>
              </Col>
              <Col className='contact-block__button-area' span={24}>
                <Form.Item>
                  <Button type='primary' htmlType='submit' className='contact-block__button'>
                    {t('CONTAINERS.HOME.CONTACT.SEND')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </div>
  );
}
