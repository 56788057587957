export enum BlockquoteType {
  SUCCESS = '{.is-success}',
  INFO = '{.is-info}',
  WARNING = '{.is-warning}',
  DANGER = '{.is-danger}',
}

export enum BlockquoteTypeId {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
}
