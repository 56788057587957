import { VIEW } from '@constants/layout.constants';
import { type ImageFormats } from '@models/image.inteface';

export const getImageHeight = (formats: ImageFormats, view: VIEW): number => {
  switch (view) {
    case VIEW.MOBILE:
      return formats.thumbnail.height;
    case VIEW.TABLET:
      return formats.small.height;
    case VIEW.PC:
      return formats.medium.height;
  }
};

export const getImagePath = (formats: ImageFormats, view: VIEW): string => {
  switch (view) {
    case VIEW.MOBILE:
      return formats.thumbnail.url;
    case VIEW.TABLET:
      return formats.small.url;
    case VIEW.PC:
      return formats.medium.url;
  }
};
