import React from 'react';
import './MDContent.scss';
import '../article-markdown-block/ArticleMarkdownBlock.scss';
import ReactMarkdown from 'react-markdown';
import { type ImageData } from '@utils/markdown/modify-article-image';
import remarkGfm from 'remark-gfm';
import remarkSmartypants from 'remark-smartypants';
import rehypeRaw from 'rehype-raw';

export default function MDContent({ content, images }: { content: string; images?: ImageData[] }): JSX.Element {
  return (
    <div className='md-content article-markdown-block'>
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkSmartypants]}
        rehypePlugins={[rehypeRaw]}
        components={{
          img: ({ node, ...props }) => {
            const width = images?.find(i => i.link === node?.properties?.src)?.width;
            return <img {...props} style={{ width: width ?? 'auto' }} />;
          },
        }}>
        {content}
      </ReactMarkdown>
    </div>
  );
}
