import { VIEW } from '@constants/layout.constants';
import { type BlockElement } from '@models/storage-page.interface';
import { getImageHeight, getImagePath } from '@utils/get-image-parameters-by-view';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './FeatureBlock.scss';

export default function FeatureBlock({
  item,
  isDescriptionFirst,
  backgroundColor,
  view,
}: {
  item: BlockElement;
  isDescriptionFirst: boolean;
  backgroundColor: string;
  view: VIEW;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className='feature-block' style={{ backgroundColor }}>
      <Row gutter={[32, 24]}>
        {item.image.data ? (
          <Col xs={24} sm={24} md={24} lg={14} xl={14} order={isDescriptionFirst || view === VIEW.MOBILE ? 1 : 0}>
            <div
              style={{
                backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL ?? ''}${getImagePath(item.image.data.attributes.formats, view)})`,
                height: getImageHeight(item.image.data.attributes.formats, view),
              }}
              className='feature-block__image'></div>
            {view === VIEW.MOBILE && (
              <Button type='primary' className='feature-block__button'>
                {t('CONTAINERS.HOME.ECOSYSTEM.STORAGE.KNOW_MORE')}
              </Button>
            )}
          </Col>
        ) : (
          <></>
        )}
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <div className='flex flex-column feature-block__description-area'>
            <h2 className='feature-block__title'>{item.title}</h2>
            <p className='feature-block__description'>{item.content}</p>
            {view !== VIEW.MOBILE && (
              <Button type='primary' className='cell flex-0 feature-block__button'>
                {t('CONTAINERS.HOME.ECOSYSTEM.STORAGE.KNOW_MORE')}
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
