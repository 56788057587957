import React from 'react';
import './HomeMainInfo.scss';
import { Col, Row, Button } from 'antd';

import { useTranslation } from 'react-i18next';
import { scrollToElement } from '@utils/scroll-to-element';
import { HOME_FOOTER_ID, VIEW } from '@constants/layout.constants';

export default function HomeMainInfo({ view }: { view: VIEW }): JSX.Element {
  const { t } = useTranslation();
  return (
    <Row className='home-main-info__info' gutter={[50, 32]}>
      <Col sm={24} md={24} lg={12} xl={12}>
        <div className='flex flex-column home-main-info__left-section'>
          <h1 className='cell flex-0 home-main-info__title'>
            <span className='home-main-info__name'>
              <span className='home-main-info__title--color'>{t('CONTAINERS.HOME.NAME')}</span>
            </span>
            <span>{t('CONTAINERS.HOME.DESCRIPTION')}</span>
          </h1>
          <div className='cell flex flex-column home-main-info__description'>
            <div>{t('CONTAINERS.HOME.DETAILED_DESCRIPTION_1')}</div>
            <div>{t('CONTAINERS.HOME.DETAILED_DESCRIPTION_2')}</div>
            <div>{t('CONTAINERS.HOME.DETAILED_DESCRIPTION_3')}</div>
          </div>
          <Button
            onClick={() => {
              scrollToElement(HOME_FOOTER_ID);
            }}
            type='primary'
            className='cell flex-0 home-main-info__button'>
            {t('CONTAINERS.HOME.CONSULTATION')}
          </Button>
        </div>
      </Col>
      <Col sm={24} md={24} lg={12} xl={12}>
        <div className='flex flex-column home-main-info__right-area'>
          <div className='flex home-main-info__right-top-blocks-area'>
            <div className='flex flex-column home-main-info__right-top-block'>
              <div>
                <img src='images/star.svg' alt='star' className='home-main-info__star-image' />
              </div>
              <div>{t('CONTAINERS.HOME.IMPORT')}</div>
            </div>
            <div className='flex flex-column home-main-info__right-top-block'>
              <div>
                <img src='images/star.svg' alt='star' className='home-main-info__star-image' />
              </div>
              <div>{t('CONTAINERS.HOME.REGISTRY')}</div>
            </div>
          </div>
          {view !== VIEW.MOBILE && (
            <div className='home-main-info__image-container'>
              <div className='home-main-info__right-bottom-block home-main-info__archive'>
                <div className='flex flex-column'>
                  <h2 className='home-main-info__right-bottom-title'>{t('CONTAINERS.HOME.ARCHIVE')}</h2>
                  <p className='home-main-info__right-bottom-description home-main-info__right-bottom-description--archive'>
                    {t('CONTAINERS.HOME.ARCHIVE_DETAILS')}
                  </p>
                </div>
              </div>
              <div className='home-main-info__right-bottom-block home-main-info__storage'>
                <div className='flex flex-column'>
                  <h2 className='home-main-info__right-bottom-title'>{t('CONTAINERS.HOME.STORAGE')}</h2>
                  <p className='home-main-info__right-bottom-description'>{t('CONTAINERS.HOME.STORAGE_DETAILS')}</p>
                </div>
              </div>
              <div className='home-main-info__image'></div>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
}
