import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tokenHeaderSetter } from '@utils/token-header-setter';
import { type Data } from '@models/data.interface';
import { type ArchivePage, type ProductPage } from '@models/storage-page.interface';

export const pagesApi = createApi({
  reducerPath: 'pagesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: tokenHeaderSetter,
  }),
  endpoints: builder => ({
    getStoragePage: builder.query<Data<ProductPage>, string>({
      query: () => 'zakroma-storage?populate[0]=productFeatures.elements.image&populate[1]=solvedTasks.elements.image',
    }),
    getArchivePage: builder.query<Data<ArchivePage>, string>({
      query: () => 'zakroma-archive?populate[0]=productFeatures.elements.image',
    }),
  }),
});

export const { useGetStoragePageQuery, useGetArchivePageQuery } = pagesApi;
