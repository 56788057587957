import { useGetArchivePageQuery } from '@api/pages';
import ArchiveFeatures from '@components/archive-features/ArchiveFeatures';
import HomeFooter from '@components/home-footer/HomeFooter';
import StorageAdvantages from '@components/storage-advantages/StorageAdvantages';
import StorageDescription from '@components/storage-description/StorageDescription';
import StorageFeatures from '@components/storage-features/StorageFeatures';
import StorageMain from '@components/storage-main/StorageMain';
import { useViewMode } from '@hooks/use-window-size';
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import './ZakromaArchive.scss';
import { useTranslation } from 'react-i18next';
import { VIEW } from '@constants/layout.constants';

const DESCRIPTION_FOR_SMALL_SCREEN = [
  {
    id: 1,
    size: 12,
  },
  {
    id: 3,
    size: 12,
  },
  {
    id: 'parent-1',
    size: 8,
    children: [
      {
        id: 4,
        size: 24,
      },
      {
        id: 5,
        size: 24,
      },
    ],
  },
  {
    id: 'parent-2',
    size: 8,
    children: [
      {
        id: 6,
        size: 24,
      },
      {
        id: 7,
        size: 24,
      },
    ],
  },
  {
    id: 2,
    size: 8,
  },
];

const DESCRIPTION_FOR_BIG_SCREEN = [
  {
    id: 'parent-1',
    size: 12,
    children: [
      {
        id: 1,
        size: 24,
      },
      {
        id: 2,
        size: 24,
      },
    ],
  },
  {
    id: 3,
    size: 6,
  },
  {
    id: 4,
    size: 6,
  },
  {
    id: 5,
    size: 8,
  },
  {
    id: 6,
    size: 8,
  },
  {
    id: 7,
    size: 8,
  },
];

const DESCRIPTION_FOR_MOBILE_SCREEN = [
  {
    id: 1,
    size: 24,
  },
  {
    id: 3,
    size: 24,
  },
  {
    id: 6,
    size: 24,
  },
  {
    id: 2,
    size: 24,
  },
  {
    id: 5,
    size: 24,
  },
  {
    id: 7,
    size: 24,
  },
];

const ARCHIVE_ADVANTAGES = [
  {
    title: 'CONTAINERS.HOME.ECOSYSTEM.ARCHIVE.DOC_TYPES',
    icon: '/images/archive.svg',
  },
  {
    title: 'CONTAINERS.HOME.ECOSYSTEM.ARCHIVE.VOLUME',
    icon: '/images/storage.svg',
  },
  {
    title: 'CONTAINERS.HOME.ECOSYSTEM.ARCHIVE.INTERFACE',
    icon: '/images/notebook.svg',
  },
  {
    title: 'CONTAINERS.HOME.ECOSYSTEM.ARCHIVE.ATTRIBUTES',
    icon: '/images/select.svg',
  },
  {
    title: 'CONTAINERS.HOME.ECOSYSTEM.ARCHIVE.ACCESS_CONTROL',
    icon: '/images/options.svg',
  },
  {
    title: 'CONTAINERS.HOME.ECOSYSTEM.ARCHIVE.RELIABILITY',
    icon: '/images/safety.svg',
  },
];

export default function ZakromaArchive(): JSX.Element {
  const { t } = useTranslation();
  const view = useViewMode();
  const { data, isFetching } = useGetArchivePageQuery('');

  useEffect(() => {
    document.title = t('COMPONENT.NAVBAR.ARCHIVE');
  }, []);

  return (
    <div className='flex flex-column'>
      {isFetching ? <Spin /> : <></>}
      {!isFetching && data?.data && (
        <>
          <div className={`zakroma-archive flex flex-column ${view === VIEW.PC ? 'zakroma-archive__content' : ''}`}>
            <StorageMain
              view={view}
              title='CONTAINERS.HOME.ECOSYSTEM.ARCHIVE.TITLE'
              description='CONTAINERS.HOME.ECOSYSTEM.ARCHIVE.DESCRIPTION'
              color={'black'}
              iconPath='/images/document-file.svg'
              imagePath='/images/zakroma-archive.jpeg'
            />
            <StorageAdvantages items={ARCHIVE_ADVANTAGES} />
            <div className='zakroma-archive__description'>
              {view !== VIEW.MOBILE && (
                <>
                  <div className='zakroma-archive__document-security zakroma-archive__icon'></div>
                  <div className='zakroma-archive__personal-data zakroma-archive__icon'></div>
                  <div className='zakroma-archive__archive zakroma-archive__icon'></div>
                </>
              )}
              <StorageDescription
                view={view}
                mobileConfig={DESCRIPTION_FOR_MOBILE_SCREEN}
                tabletConfig={DESCRIPTION_FOR_SMALL_SCREEN}
                config={DESCRIPTION_FOR_BIG_SCREEN}
                prefix='CONTAINERS.PRODUCT.ARCHIVE.DESCRIPTION'
              />
            </div>
            <ArchiveFeatures view={view} />
            <StorageFeatures block={data.data.attributes.productFeatures} view={view} />
          </div>
          <div className='zakroma-archive__footer'>
            <HomeFooter view={view} />
          </div>
        </>
      )}
    </div>
  );
}
