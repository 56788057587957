import { type RefObject } from 'react';

export const getIndexesHiddenElements = ({
  containerRef,
  childrenWidthGap = 0,
  additionalWidth = 0,
  haveMoreButton = false,
}: {
  containerRef: RefObject<HTMLDivElement | HTMLInputElement>;
  childrenWidthGap?: number;
  additionalWidth?: number;
  haveMoreButton?: boolean;
}): number[] | undefined => {
  if (!containerRef?.current) return;

  const fullWidth = containerRef.current.clientWidth - (haveMoreButton ? additionalWidth : 0);

  const containerChildren = containerRef.current.children[0];

  const { length: childrenLength } = containerChildren.children;
  const childrenArray = Array.from(containerChildren.children);

  const childrenWidth = childrenArray.reduce((a, b) => a + b.clientWidth, 0) + (childrenLength - 1) * childrenWidthGap;

  if (childrenWidth > fullWidth) {
    const totalChildrenIndexes: number[] = [];

    let totalWidth = childrenWidth;

    [...childrenArray].reverse().every((item, i) => {
      const childrenIndex = childrenLength - 1 - i;

      totalWidth -= item.clientWidth + childrenWidthGap;

      totalChildrenIndexes.push(childrenIndex);

      return totalWidth + additionalWidth >= fullWidth;
    });

    return totalChildrenIndexes;
  }
};
