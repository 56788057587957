import React from 'react';
import { type Author } from '@models/author.interface';

function BlogAuthorBlock({ author }: { author: Author }): JSX.Element {
  const { avatar } = author?.attributes ?? {};

  const avatarUrl = `${process.env.REACT_APP_IMAGE_URL ?? ''}${avatar?.data?.attributes?.url ?? ''}`;

  return (
    <div className='flex align-center blog-article__author'>
      {avatar?.data && (
        <div className='blog-article__author__image' style={{ backgroundImage: `url(${avatarUrl})` }}></div>
      )}
      <div>{author.attributes?.name}</div>
    </div>
  );
}

export default BlogAuthorBlock;
