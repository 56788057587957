import { useGetArticlesBySearchQuery } from '@api/articles';
import { useGetWikiSectionBySearchQuery } from '@api/wiki-sektions';
import SearchInput from '@components/search-input/SearchInput';
import SearchResultTitle from '@components/search-result-title/SearchResultTitle';
import { WIKI_SEARCH_ID } from '@constants/routers.const';
import { INPUT_DEBOUNCE } from '@constants/time.const';
import { useDebounce } from '@hooks/use-debounce';
import { skipToken } from '@reduxjs/toolkit/query';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './Search.scss';
import SearchResultList from '@components/search-result-list/SearchResultList';
import { useTranslation } from 'react-i18next';
import { useProductContext } from '@context/product/Product.context';

export default function Search(): JSX.Element {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { product } = useProductContext();
  const debouncedSearchTerm = useDebounce(searchParams.get(WIKI_SEARCH_ID) ?? '', INPUT_DEBOUNCE);
  const { data: articlesData, isFetching: isArticlesFetching } = useGetArticlesBySearchQuery(
    debouncedSearchTerm
      ? {
          search: debouncedSearchTerm,
          isShort: false,
          product,
        }
      : skipToken,
  );
  const { data: wikisData, isFetching: isWikisFetching } = useGetWikiSectionBySearchQuery(
    debouncedSearchTerm
      ? {
          search: debouncedSearchTerm,
          isShort: false,
          product,
        }
      : skipToken,
  );

  useEffect(() => {
    document.title = t('COMPONENT.NAVBAR.DOCS');
  }, []);

  return (
    <div className='flex flex-column search'>
      <SearchResultTitle />
      <SearchInput focus={true} />
      <div className='search__results'>
        <SearchResultList
          articlesData={articlesData?.data}
          isArticlesFetching={isArticlesFetching}
          isWikisFetching={isWikisFetching}
          wikisData={wikisData?.data}
          search={searchParams.get(WIKI_SEARCH_ID) ?? ''}
          isAllResult
        />
      </div>
    </div>
  );
}
