import { Empty } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './RouterErrorPage.scss';

export default function RouterErrorPage(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className='flex align-center center router-error-page'>
      <Empty description={t('CONTAINERS.ROUTER_ERROR_PAGE.DESCRIPTION')} />
    </div>
  );
}
