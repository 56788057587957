import { type DescriptionItem } from '@models/storage-description-item.interface';
import { Col } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './StorageDescriptionItem.scss';

export default function StorageDescriptionItem({
  item,
  prefix,
}: {
  item: DescriptionItem;
  prefix: string;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Col key={item.id} span={item.size} className='storage-description-item__col'>
      <div className='flex flex-column storage-description-item'>
        <h3 className='storage-description-item__title'>{t(`${prefix}.ITEM_${item.id}.TITLE`)}</h3>
        <p className='storage-description-item__description'>{t(`${prefix}.ITEM_${item.id}.DESCRIPTION`)}</p>
      </div>
    </Col>
  );
}
