import { Input } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import './SideSearch.scss';
import { useDebounce } from '@hooks/use-debounce';
import { INPUT_DEBOUNCE } from '@constants/time.const';
import { useGetArticlesBySearchQuery } from '@api/articles';
import { useGetWikiSectionBySearchQuery } from '@api/wiki-sektions';
import { skipToken } from '@reduxjs/toolkit/query';
import SearchResultList from '@components/search-result-list/SearchResultList';
import { useProductContext } from '@context/product/Product.context';

export default function SideSearch(): JSX.Element {
  const { t } = useTranslation();
  const { product } = useProductContext();
  const [search, setSearch] = useState('');
  const debouncedSearchTerm = useDebounce(search, INPUT_DEBOUNCE);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value);
  };
  const { data: articlesData, isFetching: isArticlesFetching } = useGetArticlesBySearchQuery(
    debouncedSearchTerm
      ? {
          search: debouncedSearchTerm,
          isShort: true,
          product,
        }
      : skipToken,
  );
  const { data: wikisData, isFetching: isWikisFetching } = useGetWikiSectionBySearchQuery(
    debouncedSearchTerm
      ? {
          search: debouncedSearchTerm,
          isShort: true,
          product,
        }
      : skipToken,
  );

  return (
    <div>
      <Input
        className='side-search__input'
        placeholder={t('GENERAL.SEARCH')}
        prefix={<SearchOutlined />}
        allowClear
        value={search}
        onChange={handleSearchChange}
      />
      {search && (
        <div className='side-search__results'>
          <SearchResultList
            articlesData={articlesData?.data}
            isArticlesFetching={isArticlesFetching}
            isWikisFetching={isWikisFetching}
            wikisData={wikisData?.data}
            search={search}
            setSearch={setSearch}
          />
        </div>
      )}
    </div>
  );
}
