import React, { useState } from 'react';
import './Footer.scss';
import { Menu, type MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LocationIcon } from '@icons/location.svg';
import Icon from '@ant-design/icons';
import { ReactComponent as CallIcon } from '@icons/call.svg';
import { getMenuItems } from '@utils/get-menu-items';
import { ZAKROMA_TELEGRAM } from '@constants/other.const';
import { VIEW } from '@constants/layout.constants';
import MobileNavigationMenu from '@components/mobile-navigation-menu/MobileNavigationMenu';

export default function Footer({ view }: { view: VIEW }): JSX.Element {
  const { t } = useTranslation();
  const [menuItems] = useState<MenuProps['items']>(getMenuItems());

  return (
    <div className='footer flex flex-column'>
      <div className='flex space-between'>
        <div className='flex cell footer__logo'></div>
        {view !== VIEW.MOBILE && (
          <Menu style={{ flex: 'auto' }} mode={'horizontal'} items={menuItems} className='footer__menu' />
        )}
      </div>
      {view === VIEW.MOBILE && <MobileNavigationMenu />}
      <div className='flex footer__contacts'>
        <div className='cell flex-0 flex footer__cell'>
          <Icon className='footer__cell-icon' component={LocationIcon} />
          <div className='flex flex-column footer__cell--vertical'>
            <div>
              <span>{t('GENERAL.ADDRESS')}</span>
            </div>
            <div>
              <span className='footer__company-name'>{t('CONTAINERS.HOME.FOOTER.COMPANY')}</span>
            </div>
          </div>
        </div>
        <div className='cell flex-0 flex footer__cell'>
          <Icon className='footer__cell-icon' component={CallIcon} />
          <div className='flex flex-column footer__cell--vertical'>
            <div>
              <span>{t('GENERAL.PHONE')}</span>
            </div>
          </div>
        </div>
        <div className='cell flex end footer__contact-icons'>
          <div className='cell flex-0' onClick={() => window.open(ZAKROMA_TELEGRAM, '_blank')}>
            <img className='footer__contact-icon' src='/images/telegram-white.svg' alt='telegram-white' />
          </div>
        </div>
      </div>
      <div className='footer__zakroma'>
        <span>{t('CONTAINERS.HOME.FOOTER.COMPANY_AND_YEAR')}</span>
      </div>
    </div>
  );
}
