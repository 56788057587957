import './App.scss';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import './i18n';
import { ConfigProvider } from 'antd';
import { configureStoreZakroma } from '@utils/store';
import { Provider } from 'react-redux';
import { router } from './router/router';
import { BREAK_POINT, PRIMARY_COLOR } from '@constants/layout.constants';
import { YMInitializer } from 'react-yandex-metrika';

const store = configureStoreZakroma();

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: PRIMARY_COLOR,
            fontSize: 16,
            fontFamily: 'Montserrat',
            colorLinkHover: PRIMARY_COLOR,
            colorLink: PRIMARY_COLOR,
            screenXL: BREAK_POINT.PC,
            screenXLMin: BREAK_POINT.PC,
            screenLGMax: BREAK_POINT.PC - 1,
            screenLG: BREAK_POINT.TABLET + 1,
            screenLGMin: BREAK_POINT.TABLET + 1,
            screenMDMax: BREAK_POINT.TABLET,
            screenSM: BREAK_POINT.MOBILE,
            screenSMMin: BREAK_POINT.MOBILE,
            screenXSMax: BREAK_POINT.MOBILE - 1,
            screenXS: BREAK_POINT.MOBILE - 1,
            screenXSMin: 0,
          },
          components: {
            Menu: {
              horizontalItemSelectedColor: PRIMARY_COLOR,
              itemSelectedColor: PRIMARY_COLOR,
              colorLink: 'black',
              itemColor: 'black',
            },
          },
        }}>
        <YMInitializer
          accounts={[Number(process.env.REACT_APP_YANDEX_METRIKA_KEY)]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
          }}
        />
        <RouterProvider router={router} />
      </ConfigProvider>
    </Provider>
  );
}

export default App;
