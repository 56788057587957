import { VIEW } from '@constants/layout.constants';
import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './ArchiveFeatures.scss';

export default function ArchiveFeatures({ view }: { view: VIEW }): JSX.Element {
  const { t } = useTranslation();
  const features = [
    {
      title: 'CONTAINERS.PRODUCT.ARCHIVE.FEATURES.ITEM_1.TITLE',
      description: 'CONTAINERS.PRODUCT.ARCHIVE.FEATURES.ITEM_1.DESCRIPTION',
      image: '/images/text-type.svg',
    },
    {
      title: 'CONTAINERS.PRODUCT.ARCHIVE.FEATURES.ITEM_2.TITLE',
      description: 'CONTAINERS.PRODUCT.ARCHIVE.FEATURES.ITEM_2.DESCRIPTION',
      image: '/images/file-history.svg',
    },
    {
      title: 'CONTAINERS.PRODUCT.ARCHIVE.FEATURES.ITEM_3.TITLE',
      description: 'CONTAINERS.PRODUCT.ARCHIVE.FEATURES.ITEM_3.DESCRIPTION',
      image: '/images/filters.svg',
    },
    {
      title: 'CONTAINERS.PRODUCT.ARCHIVE.FEATURES.ITEM_4.TITLE',
      description: 'CONTAINERS.PRODUCT.ARCHIVE.FEATURES.ITEM_4.DESCRIPTION',
      image: '/images/data-protection.svg',
    },
    {
      title: 'CONTAINERS.PRODUCT.ARCHIVE.FEATURES.ITEM_5.TITLE',
      description: 'CONTAINERS.PRODUCT.ARCHIVE.FEATURES.ITEM_5.DESCRIPTION',
      image: '/images/search.svg',
    },
    {
      title: 'CONTAINERS.PRODUCT.ARCHIVE.FEATURES.ITEM_6.TITLE',
      description: 'CONTAINERS.PRODUCT.ARCHIVE.FEATURES.ITEM_6.DESCRIPTION',
      image: '/images/file-structure.svg',
    },
    {
      title: 'CONTAINERS.PRODUCT.ARCHIVE.FEATURES.ITEM_7.TITLE',
      description: 'CONTAINERS.PRODUCT.ARCHIVE.FEATURES.ITEM_7.DESCRIPTION',
      image: '/images/storage-icon.svg',
    },
    {
      title: 'CONTAINERS.PRODUCT.ARCHIVE.FEATURES.ITEM_8.TITLE',
      description: 'CONTAINERS.PRODUCT.ARCHIVE.FEATURES.ITEM_8.DESCRIPTION',
      image: '/images/personal-development.svg',
    },
  ];

  return (
    <div className='flex flex-column archive-features'>
      <h1 className='archive-features__title'>{t('CONTAINERS.PRODUCT.ARCHIVE.FEATURES.TITLE')}</h1>
      <Row gutter={view !== VIEW.PC ? [24, 24] : [40, 40]}>
        {features.map(feature => (
          <Col className='archive-features__col' key={feature.title} xs={24} sm={24} md={24} lg={6} xl={6}>
            <div className='flex flex-column archive-features__item'>
              <div
                className={`flex archive-features__image-and-name align-center ${view !== VIEW.MOBILE ? 'flex-column align-start' : ''}`}>
                <img src={feature.image} className='archive-features__img' />
                <h3 className='archive-features__item-title'>{t(feature.title)}</h3>
              </div>
              <p className='archive-features__description'>{t(feature.description)}</p>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}
