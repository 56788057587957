// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-menu .ant-menu-submenu-inline:not(.ant-menu-submenu-selected) > .ant-menu-submenu-title > .ant-menu-title-content a,
.side-menu .ant-menu-submenu-vertical:not(.ant-menu-submenu-selected) > .ant-menu-submenu-title > .ant-menu-title-content a {
  color: black;
}
.side-menu .ant-menu-item {
  white-space: normal;
  height: -moz-fit-content;
  height: fit-content;
}
.side-menu .ant-menu-inline.ant-menu-root .ant-menu-item {
  align-items: baseline;
}
.side-menu .ant-menu .ant-menu-item .ant-menu-item-icon {
  margin-top: 10px;
}
.side-menu .ant-menu-submenu .ant-menu-submenu-arrow {
  rotate: -90deg;
  top: 45%;
}
.side-menu .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  rotate: 180deg;
  top: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/side-menu/SideMenu.scss"],"names":[],"mappings":"AAMI;;EACE,YAAA;AAJN;AASE;EACE,mBAAA;EACA,wBAAA;EAAA,mBAAA;AAPJ;AAUE;EACE,qBAAA;AARJ;AAWE;EACE,gBAAA;AATJ;AAaI;EACE,cAAA;EACA,QAAA;AAXN;AAeE;EACE,cAAA;EACA,QAAA;AAbJ","sourcesContent":["@import '@styles/colors.scss';\n@import '@styles/constants.scss';\n\n.side-menu {\n  .ant-menu-submenu-inline:not(.ant-menu-submenu-selected)>.ant-menu-submenu-title>.ant-menu-title-content,\n  .ant-menu-submenu-vertical:not(.ant-menu-submenu-selected)>.ant-menu-submenu-title>.ant-menu-title-content {\n    a {\n      color: black;\n    }\n  }\n\n\n  .ant-menu-item {\n    white-space: normal;\n    height: fit-content;\n  }\n\n  .ant-menu-inline.ant-menu-root .ant-menu-item {\n    align-items: baseline;\n  }\n\n  .ant-menu .ant-menu-item .ant-menu-item-icon {\n    margin-top: 10px;\n  }\n\n  .ant-menu-submenu {\n    .ant-menu-submenu-arrow {\n      rotate: -90deg;\n      top: 45%;\n    }\n  }\n\n  .ant-menu-submenu-open>.ant-menu-submenu-title>.ant-menu-submenu-arrow {\n    rotate: 180deg;\n    top: 50%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
