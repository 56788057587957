import CustomTabs from '@components/tasks-tabs/TasksTabs';
import MDContent from '@components/md-content/MDContent';
import { type PageBlock } from '@models/storage-page.interface';
import { Button, Col, Row, type TabsProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './StorageTasks.scss';
import { Link } from 'react-router-dom';
import { type VIEW } from '@constants/layout.constants';
import { getImageHeight, getImagePath } from '@utils/get-image-parameters-by-view';

export default function StorageTasks({ block, view }: { block: PageBlock; view: VIEW }): JSX.Element {
  const [tabs, setTabs] = useState<TabsProps['items']>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setTabs(
      block.elements.map(el => ({
        key: el.id.toString(),
        label: el.title,
        children: (
          <div className='storage-tasks__tab'>
            <Row gutter={[52, 32]}>
              <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                <div className='flex flex-column space-between storage-tasks__container'>
                  <MDContent content={el.content} />
                  <Link to={el.link}>
                    <Button type='primary' className='storage-tasks__button'>
                      {t('CONTAINERS.HOME.ECOSYSTEM.STORAGE.KNOW_MORE')}
                    </Button>
                  </Link>
                </div>
              </Col>
              {el.image.data ? (
                <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                  <div
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL ?? ''}${getImagePath(el.image.data.attributes.formats, view)})`,
                      height: getImageHeight(el.image.data.attributes.formats, view),
                    }}
                    className='storage-tasks__image'></div>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </div>
        ),
      })),
    );
    return () => {
      setTabs([]);
    };
  }, [block, view]);

  return (
    <div className='storage-tasks flex flex-column'>
      <h1 className='storage-tasks__title'>{block.blockTitle}</h1>
      <CustomTabs tabs={tabs} view={view} />
    </div>
  );
}
