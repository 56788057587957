import React from 'react';
import './HomeEcosystemBlock.scss';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Row, Button } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as TickIcon } from '@icons/tick-circle.svg';
import { Link } from 'react-router-dom';
import { VIEW } from '@constants/layout.constants';

export default function HomeEcosystemBlock({
  view,
  advantages,
  title,
  description,
  color,
  backgroundColor,
  iconPath,
  imagePath,
  isDescriptionFirst,
  link,
  isPG,
}: {
  view: VIEW;
  advantages: string[];
  title: string;
  description: string;
  color: string;
  backgroundColor: string;
  iconPath: string;
  imagePath: string;
  isDescriptionFirst: boolean;
  link: string;
  isPG: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className='home-ecosystem-storage flex flex-column' style={{ backgroundColor }}>
      <Row gutter={[52, 0]}>
        {view === VIEW.PC ? (
          <></>
        ) : (
          <Col span={24}>
            <Row className='home-ecosystem-storage__description-area'>
              <Col sm={24} md={24} lg={14} xl={14}>
                <div className='flex flex-column'>
                  <h1 className={`home-ecosystem-storage__name home-ecosystem-storage__name--${color}`}>
                    <span
                      className={`home-ecosystem-storage__title home-ecosystem-storage__title--${color}`}
                      style={{ color, borderColor: color }}>
                      {t(title ?? '')}
                    </span>
                  </h1>
                  <p className='home-ecosystem-storage__description'>{t(description ?? '')}</p>
                  {view !== VIEW.MOBILE && (
                    <Link to={link}>
                      <Button
                        type='primary'
                        style={{ backgroundColor: color }}
                        className='cell flex-0 home-ecosystem-storage__button'>
                        {t('CONTAINERS.HOME.ECOSYSTEM.STORAGE.KNOW_MORE')}
                      </Button>
                    </Link>
                  )}
                </div>
              </Col>
              {view !== VIEW.MOBILE && (
                <Col span={10}>
                  <div style={{ backgroundImage: `url(${iconPath})` }} className='home-ecosystem-storage__icon'></div>
                </Col>
              )}
            </Row>
          </Col>
        )}
        <Col span={view !== VIEW.PC ? 24 : 14} order={isDescriptionFirst ? 1 : 0}>
          <div className='flex flex-column home-ecosystem-storage__image-and-button-area'>
            <div
              className={`home-ecosystem-storage__image-container ${isPG ? 'home-ecosystem-storage__image-container--pg' : ''}`}>
              <div style={{ backgroundImage: `url(${imagePath})` }} className='home-ecosystem-storage__image'></div>
            </div>
            {view === VIEW.MOBILE && (
              <Link to={link}>
                <Button
                  type='primary'
                  style={{ backgroundColor: color }}
                  className='cell flex-0 home-ecosystem-storage__button'>
                  {t('CONTAINERS.HOME.ECOSYSTEM.STORAGE.KNOW_MORE')}
                </Button>
              </Link>
            )}
          </div>
        </Col>

        {view !== VIEW.PC ? (
          <></>
        ) : (
          <Col span={10} order={isDescriptionFirst ? 0 : 1}>
            <div className='flex flex-column'>
              <h1 className={`home-ecosystem-storage__name home-ecosystem-storage__name--${color}`}>
                <span
                  className={`home-ecosystem-storage__title home-ecosystem-storage__title--${color}`}
                  style={{ color, borderColor: color }}>
                  {t(title ?? '')}
                </span>
              </h1>
              <p className='home-ecosystem-storage__description'>{t(description ?? '')}</p>
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <Link to={link}>
                    <Button
                      type='primary'
                      className='cell flex-0 home-ecosystem-storage__button'
                      style={{ backgroundColor: color }}>
                      {t('CONTAINERS.HOME.ECOSYSTEM.STORAGE.KNOW_MORE')}
                    </Button>
                  </Link>
                </Col>
                <Col span={12}>
                  <div style={{ backgroundImage: `url(${iconPath})` }} className='home-ecosystem-storage__icon'></div>
                </Col>
              </Row>
            </div>
          </Col>
        )}
      </Row>
      <Divider className='home-ecosystem-storage__divider' />
      <Row gutter={view === VIEW.PC ? [52, 22] : [16, 16]}>
        {advantages?.map(advantage => (
          <Col sm={24} md={24} lg={8} xl={8} key={advantage}>
            <div className='flex align-center home-ecosystem-storage__advantage'>
              <Icon style={{ color }} className='home-ecosystem-storage__advantage-icon' component={TickIcon}></Icon>
              <span>{t(advantage)}</span>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}
