import { HOME_FOOTER_ID, VIEW } from '@constants/layout.constants';
import { scrollToElement } from '@utils/scroll-to-element';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './StorageMain.scss';

export default function StorageMain({
  view,
  title,
  description,
  iconPath,
  imagePath,
  color,
}: {
  view: VIEW;
  title: string;
  description: string;
  iconPath: string;
  imagePath: string;
  color: string;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Row className='storage-main' gutter={[{ lg: 52, xl: 81 }, 0]}>
      <Col sm={24} md={24} lg={11} xl={11}>
        <div className='flex flex-column storage-main__content'>
          {view === VIEW.PC ? (
            <div style={{ backgroundImage: `url(${iconPath})` }} className='storage-main__icon'></div>
          ) : (
            <></>
          )}
          <h1 className={`cell flex-0 storage-main__name storage-main__name--${color}`}>
            <span className={`storage-main__title storage-main__title--${color}`} style={{ color, borderColor: color }}>
              {t(title ?? '')}
            </span>
          </h1>
          <p className={`cell storage-main__description flex ${view !== VIEW.PC ? 'flex-0' : ''}`}>{t(description)}</p>
          <Button
            type='primary'
            style={{ backgroundColor: color }}
            className='cell flex-0 storage-main__button'
            onClick={() => {
              scrollToElement(HOME_FOOTER_ID);
            }}>
            {t('GENERAL.CONSULTATION')}
          </Button>
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={13} xl={13}>
        <div className='storage-main__image-container'>
          <div style={{ backgroundImage: `url(${imagePath})` }} className='storage-main__image'></div>
        </div>
      </Col>
    </Row>
  );
}
