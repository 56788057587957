// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pre-code-area {
  position: relative;
}
.pre-code-area .syntax-highlighter {
  background-color: #F3F4F5 !important;
  padding: 24px !important;
  border-radius: 20px !important;
}
.pre-code-area .syntax-highlighter code {
  background-color: #F3F4F5 !important;
  color: #2C2A32 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/pre-code-area/PreCodeArea.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;AAFF;AAIE;EACE,oCAAA;EACA,wBAAA;EACA,8BAAA;AAFJ;AAII;EACE,oCAAA;EACA,yBAAA;AAFN","sourcesContent":["@import '@styles/colors.scss';\n@import '@styles/constants.scss';\n\n.pre-code-area {\n  position: relative;\n\n  .syntax-highlighter {\n    background-color: $background-1 !important;\n    padding: $gap !important;\n    border-radius: $border-radius !important;\n\n    code {\n      background-color: $background-1 !important;\n      color: $black !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
