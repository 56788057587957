import React from 'react';
import './HomeFooter.scss';
import ContactBlock from '@components/contact-block/ContactBlock';
import Footer from '@components/footer/Footer';
import { HOME_FOOTER_ID, type VIEW } from '@constants/layout.constants';

export default function HomeFooter({ contactTitle, view }: { contactTitle?: string; view: VIEW }): JSX.Element {
  return (
    <div className='home-footer' id={HOME_FOOTER_ID}>
      <div className='home-footer__contact-form'>
        <ContactBlock title={contactTitle} view={view} />
      </div>
      <div>
        <div className='home-footer__footer'>
          <Footer view={view} />
        </div>
      </div>
    </div>
  );
}
