import React from 'react';
import './StorageFeatures.scss';
import FeatureBlock from '@components/feature-block/FeatureBlock';
import { type PageBlock } from '@models/storage-page.interface';
import { type VIEW } from '@constants/layout.constants';

export default function StorageFeatures({ block, view }: { block: PageBlock; view: VIEW }): JSX.Element {
  const colors = ['#F8F4FF', '#F3F4F5', '#F4FBFF', '#FFF4FA'];
  const getColorByIndex = (index: number): string => {
    return colors[index - colors.length * Math.floor(index / colors.length)];
  };

  return (
    <div className='flex flex-column storage-features__features'>
      <h1 className='storage-features__title'>{block.blockTitle}</h1>
      {block.elements.map((item, index) => (
        <FeatureBlock
          key={item.id}
          item={item}
          isDescriptionFirst={index % 2 !== 0}
          backgroundColor={getColorByIndex(index)}
          view={view}
        />
      ))}
    </div>
  );
}
