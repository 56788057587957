import { type NotificationType } from '@models/notiication.type';
import { notification } from 'antd';

export const openNotification = (type: NotificationType, message: string, description?: string): void => {
  notification.open({
    type,
    message,
    duration: 3,
    description,
    placement: 'bottomRight',
  });
};
