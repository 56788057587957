import React from 'react';
import { useTranslation } from 'react-i18next';
import './StorageDetails.scss';

export default function StorageDetails(): JSX.Element {
  const { t } = useTranslation();
  const details = [
    {
      title: 'CONTAINERS.PRODUCT.STORAGE.DETAILS.MICROSERVICE',
      color: '#F8F4FF',
    },
    {
      title: 'CONTAINERS.PRODUCT.STORAGE.DETAILS.GO',
      color: '#F4FBFF',
    },
    {
      title: 'CONTAINERS.PRODUCT.STORAGE.DETAILS.CLOUD',
      color: '#FFF4FA',
    },
  ];
  return (
    <div className='flex align-center storage-details'>
      {details.map(item => (
        <div className='cell storage-details__item' key={item.title} style={{ backgroundColor: item.color }}>
          {t(item.title)}
        </div>
      ))}
    </div>
  );
}
