// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-menu-submenu:not(.ant-menu-submenu-selected) > .ant-menu-submenu-title a {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAEE;EACE,YAAA;AADJ","sourcesContent":[".ant-menu-submenu:not(.ant-menu-submenu-selected)>.ant-menu-submenu-title {\n\n  a {\n    color: black;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
