import React, { useEffect, useRef, useState } from 'react';
import './BlogCategoriesBlock.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { getIndexesHiddenElements } from '@utils/get-indexes-hidden-elements';
import { useViewMode } from '@hooks/use-window-size';
import { type BlogCategory } from '@models/blog-categories.interface';
import { VIEW } from '@constants/layout.constants';

const CATEGORY_ITEMS_SPACING = 24;
const CATEGORY_MOREBUTTON_WIDTH_MOBILE = 246;
const CATEGORY_MOREBUTTON_WIDTH_DESKTOP = 315;

function BlogCategoriesBlock({
  categories,
  categoryId,
  setCategoryId,
}: {
  categories: BlogCategory[];
  categoryId: number | null;
  setCategoryId: (value: number | null) => void;
}): JSX.Element {
  const { t } = useTranslation();

  const [viewCategories, setCategories] = useState(categories);
  const [moreCategories, setMoreCategories] = useState<BlogCategory[]>([]);

  const [categoryMoreView, setCategoryMoreView] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const view = useViewMode();

  useEffect(() => {
    const categoryIds = getIndexesHiddenElements({
      containerRef,
      childrenWidthGap: CATEGORY_ITEMS_SPACING,
      additionalWidth: view !== VIEW.PC ? CATEGORY_MOREBUTTON_WIDTH_MOBILE : CATEGORY_MOREBUTTON_WIDTH_DESKTOP,
      haveMoreButton: !!moreCategories.length,
    });

    if (categoryIds) {
      const viewCategory = viewCategories.filter((_el, i) => !categoryIds.includes(i - 1));
      setCategories(viewCategory);
      setMoreCategories(categories.filter(el => !viewCategory.find(vc => vc.id === el.id)));
    }
  }, [containerRef.current?.children[0].children[0].clientWidth]);

  return (
    <div className='categories flex flex-column'>
      <div className='categories__container flex align-center space-between' ref={containerRef}>
        <div className='categories__container flex align-center'>
          <Button
            className={`categories__button categories__${categoryId ? 'inactive' : 'active'}`}
            type={categoryId ? 'default' : 'primary'}
            onClick={() => {
              setCategoryId(null);
            }}>
            {t('CONTAINERS.BLOG.ALL')}
          </Button>
          {viewCategories.map(el => (
            <Button
              className={`categories__button categories__${el.id !== categoryId ? 'inactive' : 'active'}`}
              type={el.id !== categoryId ? 'default' : 'primary'}
              onClick={() => {
                setCategoryId(el.id);
              }}
              key={el.id}>
              {el.attributes.name}
            </Button>
          ))}
        </div>
        {!!moreCategories.length && (
          <div
            onClick={() => {
              setCategoryMoreView(!categoryMoreView);
            }}
            className={`categories__collapse flex end align-center ${categoryMoreView ? 'categories__expended' : ''}`}>
            {categoryMoreView ? t('GENERAL.COLLAPSE') : t('GENERAL.EXPAND')}
            <img src={`/images/icons/arrow-${categoryMoreView ? 'up' : 'down'}.svg`} alt='arrow' />
          </div>
        )}
      </div>
      {categoryMoreView && (
        <div className='categories__more flex flex-wrap'>
          {moreCategories.map(el => (
            <Button
              className={`categories__button categories__${el.id !== categoryId ? 'inactive' : 'active'}`}
              type={el.id !== categoryId ? 'default' : 'primary'}
              onClick={() => {
                setCategoryId(el.id);
              }}
              key={el.id}>
              {el.attributes.name}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
}

export default BlogCategoriesBlock;
