import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkSmartypants from 'remark-smartypants';
import { type AnchorLinkItemProps } from 'antd/es/anchor/Anchor';
import { useParams } from 'react-router-dom';
import './ArticleMarkdownBlock.scss';
import { generateBlockquoteId } from '@utils/markdown/generate-blockquote-id';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { base16AteliersulphurpoolLight } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import PreCodeArea from '@components/pre-code-area/PreCodeArea';
import rehypeRaw from 'rehype-raw';
import { FOOTNOTES_LABEL_ID, VIEW } from '@constants/layout.constants';
import { Divider } from 'antd';
import { type ImageData } from '@utils/markdown/modify-article-image';

enum CustomTabClass {
  ACTIVE = 'article-markdown-block__tab',
  NOT_ACTIVE = 'article-markdown-block__not-active-tab',
}

function ArticleMarkdownBlock({
  body,
  items,
  additionalClassName,
  startWithH2 = false,
  images,
  view,
}: {
  body: string;
  items: AnchorLinkItemProps[];
  additionalClassName?: string;
  images?: ImageData[];
  startWithH2?: boolean;
  view?: VIEW;
}): JSX.Element {
  const { id } = useParams();
  const syntaxTheme = base16AteliersulphurpoolLight;

  const generateSlug = (val: string, level: number, node: any): string => {
    const position = getPosition(node);
    if (position && !titlesPositions.has(position)) {
      const el = {
        key: `#${position}`,
        href: `#${position}`,
        title: val,
      };
      titlesPositions.add(position);
      if (level === 1) {
        items.push(el);
      } else if (level === 2 && items.length > 0) {
        const parentItem = items[items.length - 1];
        if (parentItem.children?.length) {
          parentItem.children.push(el);
        } else {
          parentItem.children = [el];
        }
      }
    }
    return position;
  };

  const getPosition = (node: {
    position: Undef<{ start: { line: string; column: string; offset: string } }>;
  }): string =>
    node.position ? `${node.position.start.line}X${node.position.start.column}X${node.position.start.offset}` : '';
  const titlesPositions = new Set<string>();
  const blockPositions = new Map<string, any>();
  const getTabClass = (isActive: boolean): string => (isActive ? CustomTabClass.ACTIVE : CustomTabClass.NOT_ACTIVE);
  const handleButtonClick = (nodeProperties?: RecordOf<unknown>): void => {
    const tabPanelId = nodeProperties?.tabpanelid;
    const tabId = nodeProperties?.tabid;
    if (tabPanelId && tabId) {
      const elements = document.querySelectorAll(`[tabpanelid="${tabPanelId as string}"]`);
      elements.forEach(el => {
        const elementTabId = el.getAttribute('tabid');
        const elementActive = elementTabId === tabId;
        el.setAttribute('isactive', elementActive.toString());
        if (el.tagName === 'DETAILS') {
          el.className = getTabClass(elementActive);
          (el as any).open = elementActive;
        }
      });
    }
  };

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, remarkSmartypants]}
      rehypePlugins={[rehypeRaw]}
      className={`article-markdown-block markdown--${id ?? ''} ${additionalClassName ?? ''}`}
      components={{
        /* eslint react/prop-types: 0 */
        h1: ({ node, ...props }) => (
          <h1 id={generateSlug(props.children[0] as string, props.level, node)} {...props}></h1>
        ),
        h2: ({ node, id, ...props }) => {
          if (id !== FOOTNOTES_LABEL_ID) {
            return (
              <h2
                id={generateSlug(props.children[0] as string, props.level - (startWithH2 ? 1 : 0), node)}
                {...props}></h2>
            );
          }
          return <Divider />;
        },
        h3: ({ node, ...props }) => (
          <h3 id={startWithH2 ? generateSlug(props.children[0] as string, props.level - 1, node) : ''} {...props}></h3>
        ),
        img: ({ node, ...props }) => {
          const width = images?.find(i => i.link === node?.properties?.src)?.width;
          return <img {...props} style={{ width: width ?? 'auto' }} />;
        },
        a: ({ node, id, ...props }) => {
          return <a {...props} id={id} style={{ scrollMarginTop: `${view === VIEW.PC ? 125 : 120}px` }} />;
        },
        blockquote: ({ node, ...props }) => {
          const position = getPosition(node as any);
          if (!blockPositions.has(position)) {
            const { id, children } = generateBlockquoteId(props.children);
            blockPositions.set(position, { id, children });
            return <blockquote id={id}>{children}</blockquote>;
          } else {
            const { id, children } = blockPositions.get(position);
            return <blockquote id={id}>{children}</blockquote>;
          }
        },
        pre: PreCodeArea,
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className ?? 'language-none');

          return !inline && match ? (
            <SyntaxHighlighter
              style={syntaxTheme as any}
              PreTag='div'
              language={match[1]}
              {...props}
              showLineNumbers={true}
              className='syntax-highlighter'>
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={`${className ?? ''} article-markdown-block__inlineCode`} {...props}>
              {children}
            </code>
          );
        },
        button: ({ node, ...props }) => {
          return (
            <button
              onClick={() => {
                handleButtonClick(node.properties);
              }}
              {...props}
              className={`${node.properties?.tabpanelid ? 'article-markdown-block__tab-button' : ''}`}
            />
          );
        },
        details: ({ node, ...props }) => {
          const tabPanelId = node.properties?.tabpanelid;
          const isActive = node.properties?.isactive === 'true';
          return (
            <details
              {...props}
              open={!!(tabPanelId && isActive)}
              className={`${tabPanelId ? getTabClass(isActive) : ''}`}
            />
          );
        },
      }}>
      {body}
    </ReactMarkdown>
  );
}

export default ArticleMarkdownBlock;
