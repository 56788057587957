import React, { useEffect, useState } from 'react';
import './BlogArticle.scss';
import { useGetBlogArticleQuery } from '@api/blog-article';
import { useParams } from 'react-router';
import BlogBreadcrumbs from '@components/blog-breadcrumbs/BlogBreadcrumbs';
import { Anchor, Col, Row, Spin } from 'antd';
import ArticleMarkdownBlock from '@components/article-markdown-block/ArticleMarkdownBlock';
import { type AnchorLinkItemProps } from 'antd/es/anchor/Anchor';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { useViewMode } from '@hooks/use-window-size';
import { SMALL_NAVBAR_HEIGHT, VIEW } from '@constants/layout.constants';
import BlogAuthorBlock from '@components/blog-author-block/BlogAuthorBlock';
import BlogShareBlock from '@components/blog-share-block/BlogShareBlock';
import ArticleTableOfContent from '@components/article-table-of-content/ArticleTableOfContent';
import { useNavigate } from 'react-router-dom';
import { ROUTERS } from '@constants/routers.const';

function BlogArticle(): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isFetching } = useGetBlogArticleQuery(id ?? '');
  const {
    blog_category: category,
    title,
    description,
    cover,
    publishedAt,
    body,
    author,
    timeToRead,
  } = data?.data[0].attributes ?? {};

  const [menuItems, setMenuItems] = useState<AnchorLinkItemProps[]>([]);
  const view = useViewMode();

  const items: AnchorLinkItemProps[] = [];

  const imgUrl = `${process.env.REACT_APP_IMAGE_URL ?? ''}${cover?.data?.attributes?.url ?? ''}`;

  useEffect(() => {
    if (id) {
      const markdown = document.getElementsByClassName(`markdown--${id}`);
      if (markdown) {
        setMenuItems(items);
      }
    }
  }, [id, data]);

  useEffect(() => {
    if (data) {
      document.title = data.data[0].attributes.title;
    }
  }, [data]);

  if (isFetching) {
    return <Spin />;
  }

  return (
    <div className='slow'>
      {data && (
        <div className='blog-article flex flex-column'>
          <BlogBreadcrumbs blogTitle={title ?? ''} category={category?.data ?? null} />
          <div className='flex flex-column blog-article__container'>
            <div className='flex blog-article__header'>
              <div className='flex flex-column blog-article__header__body'>
                <div className='flex flex-column blog-article__header__text'>
                  <h1 className='blog-article__header__title'>{title}</h1>
                  <div className='blog-article__header__description'>{description}</div>
                </div>
                <div className='flex align-center blog-article__header__info'>
                  {category?.data && (
                    <div
                      onClick={() => {
                        navigate(`${ROUTERS.blog}/?category=${category.data.id}`);
                      }}
                      className='blog-article__header__category'>
                      {category.data.attributes.name}
                    </div>
                  )}
                  {publishedAt && (
                    <div className='flex align-center blog-article__header__data'>
                      <img src='/images/icons/calendar.svg' alt='calendar' />

                      <div>{dayjs(publishedAt).locale('ru').format('DD MMMM YYYY')}</div>
                    </div>
                  )}
                  {timeToRead && (
                    <div className='flex align-center blog-article__header__data'>
                      <img src='/images/icons/clock.svg' alt='calendar' />

                      <div>
                        {t('CONTAINERS.BLOG.TIMEREAD', {
                          count: timeToRead,
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='blog-article__header__image-container'>
                <div className='blog-article__header__image' style={{ backgroundImage: `url(${imgUrl})` }}></div>
              </div>
            </div>
            {view !== VIEW.PC && (
              <>
                <Row gutter={[16, 16]}>
                  {author?.data && (
                    <Col span={12}>
                      <BlogAuthorBlock author={author.data} />
                    </Col>
                  )}
                  <Col span={12}>
                    <BlogShareBlock />
                  </Col>
                </Row>
                {!!menuItems.length && (
                  <div className='blog-article__menu-small'>
                    <ArticleTableOfContent items={menuItems} offset={SMALL_NAVBAR_HEIGHT} maxElementsNum={3} />
                  </div>
                )}
              </>
            )}
          </div>
          <Row gutter={[24, 24]}>
            <Col span={view !== VIEW.PC ? 24 : 20}>
              {body && (
                <ArticleMarkdownBlock
                  startWithH2
                  body={body[0].body}
                  items={items}
                  images={body[0].images}
                  additionalClassName='blog-article__markdown'
                />
              )}
            </Col>
            {view === VIEW.PC && (
              <Col span={4}>
                <div className='article__right-menu'>
                  <div className='flex flex-column article__right-section'>
                    {author?.data && <BlogAuthorBlock author={author.data} />}
                    {menuItems.length ? (
                      <div className='article__menu flex flex-column blog-article__menu'>
                        <div className='article__menu__title'>{t('CONTAINERS.ARTICLE.CONTENT')}</div>
                        <Anchor items={menuItems} offsetTop={130} />
                      </div>
                    ) : (
                      <></>
                    )}
                    <BlogShareBlock />
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
      )}
    </div>
  );
}

export default BlogArticle;
