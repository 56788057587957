import React from 'react';
import './Loader.scss';
import { Spin } from 'antd';

export default function Loader(): JSX.Element {
  return (
    <div className='flex center align-center loader'>
      <Spin />
    </div>
  );
}
