import React from 'react';
import './MainBlogCart.scss';
import { type BlogPage } from '@models/blog-page.interface';
import { Link } from 'react-router-dom';
import { processUrlString } from '@utils/produse-url-string';
import { ROUTERS } from '@constants/routers.const';

function MainBlogCart({ blogPage }: { blogPage: BlogPage }): JSX.Element {
  const {
    title,
    blog_category: category,
    publishedAt,
    cover,
    slug,
  } = blogPage?.attributes?.main_article?.data?.attributes ?? {};

  const imgUrl = `${process.env.REACT_APP_IMAGE_URL ?? ''}${cover?.data?.attributes?.url ?? ''}`;

  return (
    <Link to={processUrlString(ROUTERS.blogArticle, { id: slug })} className='flex flex-column mainblog'>
      <div className='flex flex-column mainblog__header'>
        <img className='mainblog__image' src={imgUrl} alt='blog-icon' />
        <div className='mainblog__title'>{title}</div>
      </div>
      <div className='flex space-between align-center'>
        {category?.data && <div className='mainblog__category'>{category.data.attributes.name}</div>}
        <div className='mainblog__date'>{new Date(publishedAt).toLocaleDateString()}</div>
      </div>
    </Link>
  );
}

export default MainBlogCart;
