// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.storage-advantages__item {
  padding: 16px 24px;
  border-radius: 40px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  margin: 0;
  gap: 24px;
}

@media (max-width: 1439px) {
  .storage-advantages__item {
    padding: 12px 24px;
    border-radius: 30px;
  }
}
@media (max-width: 1023px) {
  .storage-advantages__item {
    font-size: 14px;
    gap: 12px;
    border-radius: 20px;
  }
  .storage-advantages__item-image {
    width: 32px;
    height: 32px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/storage-advantages/StorageAdvantages.scss","webpack://./src/styles/constants.scss"],"names":[],"mappings":"AAIE;EACE,kBAAA;EACA,mBCEgB;EDDhB,gDCWS;EDVT,SAAA;EACA,SCTE;ADMN;;AAOA;EAEI;IACE,kBAAA;IACA,mBCViB;EDKrB;AACF;AASA;EAEI;IACE,eAAA;IACA,SAAA;IACA,mBAAA;EARJ;EAWE;IACE,WAAA;IACA,YAAA;EATJ;AACF","sourcesContent":["@import '@styles/colors.scss';\n@import '@styles/constants.scss';\n\n.storage-advantages {\n  &__item {\n    padding: 16px $gap;\n    border-radius: $border-radius-big;\n    box-shadow: $box-shadow;\n    margin: 0;\n    gap: $gap;\n  }\n}\n\n@media (max-width: $break-point) {\n  .storage-advantages {\n    &__item {\n      padding: 12px $gap;\n      border-radius: $border-radius-medium;\n    }\n  }\n}\n\n@media (max-width: $mobile-break-point) {\n  .storage-advantages {\n    &__item {\n      font-size: 14px;\n      gap: 12px;\n      border-radius: 20px;\n    }\n\n    &__item-image {\n      width: 32px;\n      height: 32px;\n    }\n  }\n}","$gap: 24px;\n$gap-large: 32px;\n$padding: 64px;\n$padding-large: 100px;\n$bold-font-weight: 600;\n$font-weight: 400;\n$border-radius: 20px;\n$border-radius-medium: 30px;\n$border-radius-big: 40px;\n$break-point: 1439px;\n$mobile-break-point: 1023px;\n$mobile-navbar-height: 65px;\n$small-navbar-height: 95px;\n$navbar-height: 111px;\n$title-font-size: 32px;\n$mobile-title-font-size: 24px;\n$mobile-subtitle-font-size: 18px;\n$title-font-size-big: 42px;\n$box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);\n$subtitle-font-size-big: 18px;\n$subtitle-font-size: 16px;\n$text-font-size: 14px;\n$content-width: 1200px;\n$max-width: 1920px;\n$icon-font-size: 24px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
