import React, { useEffect, useState } from 'react';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import './KnowledgeBaseProduct.scss';
import SideMenu from '@components/side-menu/SideMenu';
import { Button, Layout } from 'antd';
import { WIKI_SEARCH_ID, WIKI_START_ID } from '@constants/routers.const';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import WikiArticles from '@containers/wiki-articles/WikiArticles';
import type { PRODUCT } from '@constants/products.const';
import { ProductContextProvider } from '@context/product/Product.context';

const { Content, Sider } = Layout;

function KnowledgeBaseProduct(): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams();
  const { pathname } = useLocation();
  const product = pathname.split('/')[2] as PRODUCT;
  const isSearch = pathname.split('/').includes(WIKI_SEARCH_ID);
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = (): void => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    document.title = t('COMPONENT.NAVBAR.DOCS');
  }, []);

  return (
    <ProductContextProvider value={{ product }}>
      <div className='slow'>
        <Layout>
          <div>
            <Sider
              className='knowledge-base__sider'
              width={300}
              theme='light'
              collapsible
              collapsed={collapsed}
              onCollapse={toggleCollapsed}>
              {collapsed && (
                <Button
                  shape='circle'
                  className='knowledge-base__search'
                  onClick={toggleCollapsed}
                  icon={<SearchOutlined />}></Button>
              )}
              <SideMenu collapsed={collapsed} />
            </Sider>
          </div>
          <Content>
            <div className='flex flex-column knowledge-base__content'>
              {(id === WIKI_START_ID || !id) && !isSearch ? (
                <>
                  <WikiArticles />
                </>
              ) : (
                <></>
              )}
              <Outlet />
            </div>
          </Content>
        </Layout>
      </div>
    </ProductContextProvider>
  );
}

export default KnowledgeBaseProduct;
