import { VIEW } from '@constants/layout.constants';
import { type ClientListItem } from '@models/client-list-item.interface';
import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './ClientsList.scss';

export default function ClientsList({
  view,
  items,
  title,
}: {
  view: VIEW;
  items: ClientListItem[];
  title: string;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className='clients-list flex flex-column'>
      <h2 className='clients-list__title'>{t(title)}</h2>
      <Row className='clients-list__content' gutter={view !== VIEW.PC ? [24, 24] : [32, 32]}>
        {items.map(client => (
          <Col span={view !== VIEW.PC ? 8 : 6} key={client.alt}>
            <div className='clients-list__image-container'>
              <div className='clients-list__image' style={{ backgroundImage: `url(${client.img})` }} />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}
