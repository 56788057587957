import React, { useState } from 'react';
import { FileTextOutlined } from '@ant-design/icons';
import { type ArticleShortAttributes } from '@models/article.interface';
import { type DataMultiple } from '@models/data-multiple.interface';
import { useTranslation } from 'react-i18next';
import './RelativeArticles.scss';
import { Link } from 'react-router-dom';
import { ROUTERS } from '@constants/routers.const';
import { processUrlString } from '@utils/produse-url-string';
import { Button } from 'antd';
import { useProductContext } from '@context/product/Product.context';

export default function RelativeArticles({
  references,
  maxElementsNum,
}: {
  references: DataMultiple<{ attributes: ArticleShortAttributes }>;
  maxElementsNum?: number;
}): JSX.Element {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);
  const { product } = useProductContext();
  return (
    <div className='relative-article__menu flex flex-column'>
      <div className='article__menu__title'>{t('CONTAINERS.ARTICLE.RELATIVE')}</div>
      {references.data
        .filter(
          (_el, index) =>
            /* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */
            (maxElementsNum && collapsed && index < maxElementsNum) ||
            !maxElementsNum ||
            (maxElementsNum && !collapsed),
        )
        .map(item => (
          <Link
            to={processUrlString(ROUTERS.article(product), { id: item.attributes.slug })}
            key={item.attributes.slug}
            className='flex align-center relative-article__content'>
            <FileTextOutlined className='relative-article__content-icon' />
            <div>{item.attributes.title}</div>
          </Link>
        ))}

      {maxElementsNum && references.data.length > maxElementsNum ? (
        <Button
          className='relative-article__button'
          type='link'
          onClick={() => {
            setCollapsed(!collapsed);
          }}>
          {!collapsed ? t('GENERAL.LESS') : t('GENERAL.MORE')}
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
}
