import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';
import { useSendBlogSubscriptionMutation } from '@api/blog-subscription';

function BlogWelcomeBlock(): JSX.Element {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [sendBlogSubscription] = useSendBlogSubscriptionMutation();

  const validateMessages = {
    required: t('GENERAL.VALIDATION.REQUIRED'),
    types: {
      email: t('GENERAL.VALIDATION.EMAIL'),
    },
  };

  const onFinish = (values: { email: string }): void => {
    void sendBlogSubscription({
      email: values.email,
      action: 'member.set',
      apikey: process.env.REACT_APP_BLOG_SUBSCRIPTION_TOKEN ?? '',
    });
  };

  return (
    <div className='flex flex-column space-between blog__welcome'>
      <div className='flex flex-column blog__maintext'>
        <h1 className='blog__description'>
          <span>{t('CONTAINERS.BLOG.WECLOME')}</span>
          <br />
          <span className='blog__name'>
            <span className='blog__description--color'>{t('CONTAINERS.BLOG.NAME')}</span>
          </span>
        </h1>
        <div className='blog__subscribe'>{t('CONTAINERS.BLOG.SUBSCRIBRE')}</div>
      </div>
      <Form
        name='basic'
        initialValues={{ email: '' }}
        onFinish={onFinish}
        autoComplete='off'
        validateMessages={validateMessages}
        form={form}>
        <Form.Item name='email' rules={[{ required: true }, { type: 'email' }]}>
          <Input
            className='input blog__email'
            placeholder={t('CONTAINERS.BLOG.SEARCH')}
            suffix={
              <Button htmlType='submit' type='primary' className='blog__button'>
                {t('CONTAINERS.BLOG.BUTTON')}
              </Button>
            }
          />
        </Form.Item>
      </Form>
    </div>
  );
}

export default BlogWelcomeBlock;
