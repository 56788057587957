import IconByName from '@components/icon-by-name/IconByName';
import { ROUTERS } from '@constants/routers.const';
import { type ArticleShort } from '@models/article.interface';
import { processUrlString } from '@utils/produse-url-string';
import React from 'react';
import { Link } from 'react-router-dom';
import './ArticlesListCart.scss';
import { useProductContext } from '@context/product/Product.context';

function ArticlesListCart({ article }: { article: ArticleShort }): JSX.Element {
  const { product } = useProductContext();
  return article ? (
    <Link
      to={processUrlString(ROUTERS.article(product), { id: article.attributes.slug })}
      className='article-cart flex flex-column'>
      <div className='article-cart__title-area flex align-center'>
        {article.attributes.icon && (
          <div className='article-cart__icon'>
            <IconByName type={article.attributes.icon} />
          </div>
        )}
        <div className='article-cart__title'>{article.attributes.title}</div>
      </div>
      <div className='article-cart__description'>{article.attributes.description}</div>
    </Link>
  ) : (
    <></>
  );
}

export default ArticlesListCart;
