// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-ecosystem {
  gap: 64px;
}
.home-ecosystem__title {
  font-size: 42px;
  margin: 0;
  text-align: center;
}

@media (max-width: 1439px) {
  .home-ecosystem {
    gap: 52px;
  }
  .home-ecosystem__title {
    font-size: 32px;
  }
}
@media (max-width: 1023px) {
  .home-ecosystem {
    gap: 24px;
  }
  .home-ecosystem__title {
    font-size: 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/home-ecosystem/HomeEcosystems.scss","webpack://./src/styles/constants.scss"],"names":[],"mappings":"AAGA;EACE,SAAA;AAFF;AAIE;EACE,eCUkB;EDTlB,SAAA;EACA,kBAAA;AAFJ;;AAMA;EACE;IACE,SAAA;EAHF;EAIE;IACE,eCHY;EDChB;AACF;AAMA;EACE;IACE,SAAA;EAJF;EAME;IACE,eCZmB;EDQvB;AACF","sourcesContent":["@import '@styles/colors.scss';\n@import '@styles/constants.scss';\n\n.home-ecosystem {\n  gap: 64px;\n\n  &__title {\n    font-size: $title-font-size-big;\n    margin: 0;\n    text-align: center;\n  }\n}\n\n@media (max-width: $break-point) {\n  .home-ecosystem {\n    gap: 52px;\n    &__title {\n      font-size: $title-font-size;\n    }\n  }\n}\n\n@media (max-width: $mobile-break-point) {\n  .home-ecosystem {\n    gap: 24px;\n\n    &__title {\n      font-size: $mobile-title-font-size;\n    }\n  }\n}","$gap: 24px;\n$gap-large: 32px;\n$padding: 64px;\n$padding-large: 100px;\n$bold-font-weight: 600;\n$font-weight: 400;\n$border-radius: 20px;\n$border-radius-medium: 30px;\n$border-radius-big: 40px;\n$break-point: 1439px;\n$mobile-break-point: 1023px;\n$mobile-navbar-height: 65px;\n$small-navbar-height: 95px;\n$navbar-height: 111px;\n$title-font-size: 32px;\n$mobile-title-font-size: 24px;\n$mobile-subtitle-font-size: 18px;\n$title-font-size-big: 42px;\n$box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);\n$subtitle-font-size-big: 18px;\n$subtitle-font-size: 16px;\n$text-font-size: 14px;\n$content-width: 1200px;\n$max-width: 1920px;\n$icon-font-size: 24px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
