import React, { useState, useEffect } from 'react';
import { Anchor, Col, Row, Spin, Breadcrumb } from 'antd';
import { type AnchorLinkItemProps } from 'antd/es/anchor/Anchor';
import { useParams, Link } from 'react-router-dom';
import { useGetArticleBySlugQuery } from '@api/article';
import './Article.scss';
import { useTranslation } from 'react-i18next';
import RelativeArticles from '@components/relative-articles/RelativeArticles';
import { useViewMode } from '@hooks/use-window-size';
import { SMALL_NAVBAR_HEIGHT, VIEW } from '@constants/layout.constants';

import ArticleTableOfContent from '@components/article-table-of-content/ArticleTableOfContent';
import { processUrlString } from '@utils/produse-url-string';
import { ROUTERS } from '@constants/routers.const';
import { type WikiSection } from '@models/wiki-section.interface';
import { type BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb';
import ArticleMarkdownBlock from '@components/article-markdown-block/ArticleMarkdownBlock';
import { type Block } from '@models/block.interface';
import ArticleVectorSelector from '@components/article-version-selector/ArticleVersionSelector';
import { useProductContext } from '@context/product/Product.context';

function Article(): JSX.Element {
  const { id } = useParams();
  const { data, isFetching } = useGetArticleBySlugQuery(id ?? '');
  const { product } = useProductContext();
  const { t } = useTranslation();
  const items: AnchorLinkItemProps[] = [];
  const view = useViewMode();
  const [menuItems, setMenuItems] = useState<AnchorLinkItemProps[]>([]);
  const [articleVersions, setArticleVersions] = useState<string[]>([]);
  const [currentVersion, setCurrentVersion] = useState<Nullable<Block>>(null);
  const [breadCrumpsItems, setBreadCrumps] = useState<BreadcrumbItemType[]>([]);

  useEffect(() => {
    if (data) {
      document.title = data.data[0].attributes.title;
    }
    setArticleVersions(
      data
        ? (
            data.data[0].attributes.blocks.map((block: Block) => block.version).filter(version => !!version) as string[]
          ).sort((firstVersion, secondVersion) => secondVersion.localeCompare(firstVersion))
        : [],
    );
    const parentWiki = data?.data[0]?.attributes.wiki_section.data?.attributes.parent?.data;
    const currentWiki = data?.data[0]?.attributes.wiki_section.data;
    setBreadCrumps(getBreadCrumpItem([parentWiki ?? undefined, currentWiki ?? undefined]));
  }, [data]);

  useEffect(() => {
    if (id) {
      const markdown = document.getElementsByClassName(`markdown--${id}`);
      if (markdown) {
        setMenuItems(items);
      }
    }
  }, [currentVersion]);

  useEffect(() => {
    if (articleVersions.length && data) {
      setCurrentVersion(data.data[0].attributes.blocks.find(block => block.version === articleVersions[0]) ?? null);
    } else {
      setCurrentVersion(data ? data.data[0].attributes.blocks[0] : null);
    }
  }, [articleVersions]);

  const getBreadCrumpItem = (items: Array<Undef<WikiSection>>): BreadcrumbItemType[] => {
    return items
      .filter(item => !!item)
      .map(item => ({
        title: (
          <Link to={processUrlString(ROUTERS.articles(product), { id: (item as WikiSection).id })}>
            {(item as WikiSection).attributes.name}
          </Link>
        ),
      }));
  };

  const handleVersionChange = (version: string): void => {
    setCurrentVersion(data?.data[0].attributes.blocks.find(block => block.version === version) ?? null);
  };

  if (isFetching) {
    return <Spin />;
  }

  return data?.data[0] ? (
    <>
      {breadCrumpsItems.length ? <Breadcrumb items={breadCrumpsItems} className='article__bread-crumbs' /> : <></>}
      <div className='flex flex-column article__title'>
        <h1 className='article__name'>{data.data[0].attributes.title}</h1>
      </div>
      <Row className='article' gutter={[24, 24]}>
        {view !== VIEW.PC &&
        (menuItems.length || data.data[0].attributes.references.data.length || articleVersions.length > 1) ? (
          <Col span={24}>
            <div className='flex flex-column article__right-section'>
              {articleVersions.length > 1 ? (
                <ArticleVectorSelector versions={articleVersions} onSelectVersion={handleVersionChange} />
              ) : (
                <></>
              )}
              {menuItems.length ? (
                <ArticleTableOfContent items={menuItems} offset={SMALL_NAVBAR_HEIGHT} maxElementsNum={2} />
              ) : (
                <></>
              )}
              {data.data[0].attributes.references.data.length ? (
                <RelativeArticles references={data.data[0].attributes.references} maxElementsNum={1} />
              ) : (
                <></>
              )}
            </div>
          </Col>
        ) : (
          <></>
        )}
        <Col
          span={
            view === VIEW.PC &&
            (menuItems.length || data.data[0].attributes.references.data.length || articleVersions.length > 1)
              ? 18
              : 24
          }>
          <ArticleMarkdownBlock
            additionalClassName='article__markdown'
            images={currentVersion?.images}
            body={currentVersion?.body ?? ''}
            items={items}
            view={view}
            startWithH2={true}
          />
        </Col>
        {view === VIEW.PC &&
        (menuItems.length || data.data[0].attributes.references.data.length || articleVersions.length > 1) ? (
          <Col span={6}>
            <div className='article__right-menu'>
              <div className='flex flex-column article__right-section'>
                {articleVersions.length > 1 ? (
                  <ArticleVectorSelector versions={articleVersions} onSelectVersion={handleVersionChange} />
                ) : (
                  <></>
                )}
                {menuItems.length ? (
                  <div className='article__menu flex flex-column'>
                    <div className='article__menu__title'>{t('CONTAINERS.ARTICLE.CONTENT')}</div>
                    <Anchor items={menuItems} offsetTop={160} style={{ maxHeight: '300px', overflow: 'auto' }} />
                  </div>
                ) : (
                  <></>
                )}
                {data.data[0].attributes.references.data.length ? (
                  <RelativeArticles references={data.data[0].attributes.references} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </>
  ) : (
    <></>
  );
}

export default Article;
