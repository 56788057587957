export enum PRODUCT {
  STORAGE = 'storage',
  ARCHIVE = 'archive',
  DISK = 'disk',
}

export const PRODUCTS_CONTENT = [
  {
    path: PRODUCT.STORAGE,
    title: 'CONTAINERS.KNOWLEDGE_BASE.STORAGE.TITLE',
    icon: 'DatabaseOutlined',
    description: 'CONTAINERS.KNOWLEDGE_BASE.STORAGE.DESCRIPTION',
  },
  {
    path: PRODUCT.ARCHIVE,
    title: 'CONTAINERS.KNOWLEDGE_BASE.ARCHIVE.TITLE',
    icon: 'FolderOutlined',
    description: 'CONTAINERS.KNOWLEDGE_BASE.ARCHIVE.DESCRIPTION',
  },
  {
    path: PRODUCT.DISK,
    title: 'CONTAINERS.KNOWLEDGE_BASE.DISK.TITLE',
    icon: 'FileTextOutlined',
    description: 'CONTAINERS.KNOWLEDGE_BASE.DISK.DESCRIPTION',
  },
];

export const PRODUCTS_WIKI_ID = {
  [PRODUCT.STORAGE]: '4',
  [PRODUCT.ARCHIVE]: '9',
  [PRODUCT.DISK]: '14',
};
