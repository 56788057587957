import { PRODUCT } from '@constants/products.const';
import { createContext, useContext } from 'react';

export interface ProductContextValue {
  product: PRODUCT;
}

const ProductContext = createContext<ProductContextValue>({ product: PRODUCT.STORAGE });

export const ProductContextProvider = ProductContext.Provider;
export const useProductContext = (): ProductContextValue => useContext(ProductContext);
