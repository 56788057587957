import React from 'react';
import './HomeEcosystems.scss';
import { useTranslation } from 'react-i18next';
import HomeEcosystemBlock from '@components/home-ecosystem-block/HomeEcosystemBlock';
import { PRIMARY_BACKGROUND, PRIMARY_COLOR, SECONDARY_BACKGROUND, type VIEW } from '@constants/layout.constants';
import { ROUTERS } from '@constants/routers.const';

export default function HomeEcosystems({ view }: { view: VIEW }): JSX.Element {
  const { t } = useTranslation();
  const storageAdvantages = [
    'CONTAINERS.HOME.ECOSYSTEM.STORAGE.MEMORY',
    'CONTAINERS.HOME.ECOSYSTEM.STORAGE.POWER',
    'CONTAINERS.HOME.ECOSYSTEM.STORAGE.INTEGRATION',
    'CONTAINERS.HOME.ECOSYSTEM.STORAGE.SCALABILITY',
    'CONTAINERS.HOME.ECOSYSTEM.STORAGE.PERFORMANCE',
    'CONTAINERS.HOME.ECOSYSTEM.STORAGE.ARCHITECTURE',
  ];
  const archiveAdvantages = [
    'CONTAINERS.HOME.ECOSYSTEM.ARCHIVE.DOC_TYPES',
    'CONTAINERS.HOME.ECOSYSTEM.ARCHIVE.VOLUME',
    'CONTAINERS.HOME.ECOSYSTEM.ARCHIVE.INTERFACE',
    'CONTAINERS.HOME.ECOSYSTEM.ARCHIVE.ATTRIBUTES',
    'CONTAINERS.HOME.ECOSYSTEM.ARCHIVE.ACCESS_CONTROL',
    'CONTAINERS.HOME.ECOSYSTEM.ARCHIVE.RELIABILITY',
  ];
  return (
    <div className='flex flex-column home-ecosystem'>
      <h1 className='home-ecosystem__title'>{t('CONTAINERS.HOME.ECOSYSTEM.TITLE')}</h1>
      <HomeEcosystemBlock
        view={view}
        advantages={storageAdvantages}
        title='CONTAINERS.HOME.ECOSYSTEM.STORAGE.TITLE'
        description='CONTAINERS.HOME.ECOSYSTEM.STORAGE.DESCRIPTION'
        color={PRIMARY_COLOR}
        backgroundColor={PRIMARY_BACKGROUND}
        iconPath='images/data-migration.svg'
        imagePath='images/zakroma-storage.svg'
        isDescriptionFirst={false}
        isPG={true}
        link={ROUTERS.storage}
      />
      <HomeEcosystemBlock
        view={view}
        advantages={archiveAdvantages}
        title='CONTAINERS.HOME.ECOSYSTEM.ARCHIVE.TITLE'
        description='CONTAINERS.HOME.ECOSYSTEM.ARCHIVE.DESCRIPTION'
        color='black'
        backgroundColor={SECONDARY_BACKGROUND}
        iconPath='images/document-file.svg'
        imagePath='images/zakroma-archive.jpeg'
        isDescriptionFirst={true}
        isPG={false}
        link={ROUTERS.archive}
      />
    </div>
  );
}
