import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tokenHeaderSetter } from '@utils/token-header-setter';
import { type DataMultiple } from '@models/data-multiple.interface';
import { type BlogArticle } from '@models/blog-articles.interface';
import { modifyArticleImage } from '@utils/markdown/modify-article-image';

export const blogArticleApi = createApi({
  reducerPath: 'blogArticleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: tokenHeaderSetter,
  }),
  endpoints: builder => ({
    getBlogArticle: builder.query<DataMultiple<BlogArticle>, string>({
      query: slug =>
        `blog-articles?populate=*,author.avatar,body,blog_category,timeToRead,cover&filters[slug][$eq]=${slug}`,
      transformResponse: (response: DataMultiple<BlogArticle>) => {
        let content = response?.data[0].attributes.body[0].body;
        if (content) {
          const resultWithImage = modifyArticleImage(content);
          content = resultWithImage.content;
          Object.assign(response?.data[0].attributes, { images: resultWithImage.images });
          response.data[0].attributes.body[0].body = content;
        }
        return response;
      },
    }),
  }),
});

export const { useGetBlogArticleQuery } = blogArticleApi;
