// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.code-copy-btn {
  color: #838E9B !important;
  position: absolute;
  right: 10px;
  top: 6px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.code-copy-btn:hover {
  transform: scale(1.1);
  opacity: 0.9;
}`, "",{"version":3,"sources":["webpack://./src/components/copy-code-button/CopyCopyButton.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,kBAAA;EACA,WAAA;EACA,QAAA;EACA,eAAA;EACA,eAAA;EACA,gCAAA;AADF;;AAIA;EACE,qBAAA;EACA,YAAA;AADF","sourcesContent":["@import '@styles/colors.scss';\n\n.code-copy-btn {\n  color: $gray-hard !important;\n  position: absolute;\n  right: 10px;\n  top: 6px;\n  font-size: 12px;\n  cursor: pointer;\n  transition: all 0.3s ease-in-out;\n}\n\n.code-copy-btn:hover {\n  transform: scale(1.1);\n  opacity: 0.9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
