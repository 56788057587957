import React, { useEffect, useRef, useState } from 'react';
import './Blog.scss';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import MainBlogCart from '@components/main-blog-cart/MainBlogCart';
import { useGetBlogPageQuery } from '@api/blog-page';
import BlogWelcomeBlock from '@components/blog-welcome-block/BlogWelcomeBlock';
import { useGetBlogCategoriesQuery } from '@api/blog-categories';
import BlogCategoriesBlock from '@components/blog-categories-block/BlogCategoriesBlock';
import { useGetBlogArticlesQuery } from '@api/blog-articles';
import BlogArticlesList from '@components/blog-articles-list/BlogArticlesList';
import { useSearchParams } from 'react-router-dom';

function Blog(): JSX.Element {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const category = searchParams.get('category');

  const [searchValue, setSearchValue] = useState('');
  const [categoryId, setCategoryId] = useState<number | null>(category ? +category : null);
  const [isScrollToCategories, setIsScrollToCategories] = useState(false);

  const categoriesRef = useRef<HTMLDivElement>(null);

  const { data: BlogPageData } = useGetBlogPageQuery('');

  const { data: BlogCategoriesData } = useGetBlogCategoriesQuery('');

  const { data: BlogArticlesData } = useGetBlogArticlesQuery({ query: searchValue, categoryId });

  useEffect(() => {
    if (category && BlogCategoriesData && BlogArticlesData && categoriesRef.current && !isScrollToCategories) {
      window.scrollTo({ top: categoriesRef.current.offsetTop - 111, behavior: 'smooth' });
      setIsScrollToCategories(true);
    }
  }, [BlogCategoriesData, category, BlogArticlesData]);

  useEffect(() => {
    if (!category) {
      setCategoryId(null);
    }
  }, [category]);

  useEffect(() => {
    document.title = t('COMPONENT.NAVBAR.BLOG');
  }, []);

  return (
    <div className='slow'>
      <div className='blog flex flex-column'>
        <div className='blog__container'>
          <div className='flex space-between blog__header'>
            <BlogWelcomeBlock />
            {BlogPageData?.data?.attributes?.main_article?.data && <MainBlogCart blogPage={BlogPageData.data} />}
          </div>
          <Input
            className='input blog__search'
            placeholder={t('GENERAL.SEARCH')}
            prefix={<img src='/images/icons/search-normal.svg' />}
            value={searchValue}
            onChange={e => {
              setSearchValue(e.target.value);
            }}
          />
        </div>
        <div ref={categoriesRef}>
          {BlogCategoriesData && (
            <BlogCategoriesBlock
              categories={BlogCategoriesData.data}
              categoryId={categoryId}
              setCategoryId={setCategoryId}
            />
          )}
          {BlogArticlesData && (
            <div className='flex flex-column blog__articleslist'>
              <BlogArticlesList articles={BlogArticlesData.data} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Blog;
