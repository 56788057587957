import React from 'react';
import './HomeTasks.scss';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

export default function HomeTasks(): JSX.Element {
  const { t } = useTranslation();
  const tasks = [
    'CONTAINERS.HOME.TASKS.VOLUME',
    'CONTAINERS.HOME.TASKS.STORAGE',
    'CONTAINERS.HOME.TASKS.MIGRATION',
    'CONTAINERS.HOME.TASKS.CDN',
    'CONTAINERS.HOME.TASKS.SECURITY',
    'CONTAINERS.HOME.TASKS.REDUCE_COST',
    'CONTAINERS.HOME.TASKS.IMPORT_SUBSTITUTION',
  ];

  return (
    <div className='home-tasks'>
      <div className='home-tasks__eclipse-outline'></div>
      <div className='home-tasks__content'>
        <div className='home-tasks__description-area flex flex-column'>
          <h1 className='home-tasks__title'>{t('CONTAINERS.HOME.TASKS.TITLE')}</h1>
          <Row gutter={[22, 22]}>
            {tasks.map(task => (
              <Col sm={24} md={24} lg={8} xl={6} key={task}>
                <div className='flex home-tasks__task align-center space-between'>
                  <div>
                    <span>{t(task)}</span>
                  </div>
                  <div className='home-tasks__task-icon'></div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
}
