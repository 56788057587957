import React from 'react';
import { useTranslation } from 'react-i18next';
import './WikiWelcomeBlock.scss';

function WikiWelcomeBlock(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className='flex flex-column wiki-welcome-block'>
      <div className='wiki-welcome-block__title'>{t('COMPONENT.WELCOME_WIKI_BLOCK.TITLE')}</div>
      <div className='wiki-welcome-block__sub-title'>{t('COMPONENT.WELCOME_WIKI_BLOCK.SUB_TITLE')}</div>
    </div>
  );
}

export default WikiWelcomeBlock;
