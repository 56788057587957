import React from 'react';
import { useTranslation } from 'react-i18next';
import './BecomePartner.scss';

const STEPS = [
  {
    title: 'CONTAINERS.PARTNERS.BECOME_PARTNER.STEP_1',
    icon: '1',
  },
  {
    title: 'CONTAINERS.PARTNERS.BECOME_PARTNER.STEP_2',
    icon: '2',
  },
  {
    title: 'CONTAINERS.PARTNERS.BECOME_PARTNER.STEP_3',
    icon: '3',
  },
];

export default function BecomePartner(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className='became-partner flex flex-column'>
      <h2 className='became-partner__title'>{t('CONTAINERS.PARTNERS.BECOME_PARTNER.TITLE')}</h2>
      <div className='flex became-partner__content'>
        {STEPS.map(step => (
          <div key={step.title} className='became-partner__step flex align-center cell'>
            <div className='became-partner__step-icon flex cell flex-0 center align-center'>{step.icon}</div>
            <div className='became-partner__description'>{t(step.title)}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
