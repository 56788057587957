import { Input, type InputRef } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTERS, WIKI_SEARCH_ID } from '@constants/routers.const';
import { useProductContext } from '@context/product/Product.context';

export const SEARCH_INPUT_ID = 'search';

export default function SearchInput({ focus }: { focus?: boolean }): JSX.Element {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = useRef<InputRef>(null);
  const navigate = useNavigate();
  const { product } = useProductContext();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const search = event.target.value;
    if (search) {
      navigate({ pathname: ROUTERS.search(product), search });
      setSearchParams({ search });
    } else {
      setSearchParams({});
    }
  };

  useEffect(() => {
    if (focus) {
      inputRef.current?.focus({
        cursor: 'end',
      });
    }
  }, [focus]);

  return (
    <Input
      id={SEARCH_INPUT_ID}
      placeholder={t('GENERAL.SEARCH')}
      prefix={<SearchOutlined />}
      value={searchParams.get(WIKI_SEARCH_ID) ?? ''}
      onChange={handleSearchChange}
      ref={inputRef}
    />
  );
}
