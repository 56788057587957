import { ROUTERS } from '@constants/routers.const';
import { type MenuProps } from 'rc-menu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface MenuItem {
  label: string;
  key: string;
  link?: string;
  children?: MenuItem[];
}

export const NAV_BAR_CONFIG: MenuItem[] = [
  {
    label: 'COMPONENT.NAVBAR.PRODUCTS',
    key: 'products',
    link: ROUTERS.home,
    children: [
      {
        label: 'COMPONENT.NAVBAR.STORAGE',
        key: 'storage',
        link: ROUTERS.storage,
      },
      {
        label: 'COMPONENT.NAVBAR.ARCHIVE',
        key: 'archive',
        link: ROUTERS.archive,
      },
    ],
  },
  {
    label: 'COMPONENT.NAVBAR.INFORMATION',
    key: 'info',
    children: [
      {
        label: 'COMPONENT.NAVBAR.CLIENTS',
        key: 'clients',
        link: ROUTERS.clients,
      },
      {
        label: 'COMPONENT.NAVBAR.PARTNERS',
        key: 'partners',
        link: ROUTERS.partners,
      },
    ],
  },
  {
    label: 'COMPONENT.NAVBAR.BLOG',
    key: 'blog',
    link: ROUTERS.blog,
  },
  {
    label: 'COMPONENT.NAVBAR.DOCS',
    key: 'docs',
    link: ROUTERS.knowledgeBase,
  },
  {
    label: 'COMPONENT.NAVBAR.CONTACTS',
    key: 'contacts',
    link: ROUTERS.contacts,
  },
];

export const getMenuItems = (): MenuProps['items'] => {
  const { t } = useTranslation();
  return NAV_BAR_CONFIG.map(item => ({
    label: item.link ? <Link to={item.link}> {t(item.label)}</Link> : <div>{t(item.label)}</div>,
    key: item.key,
    children: item.children?.map(child => ({
      label: child.link ? <Link to={`${child.link}`}> {t(child.label)}</Link> : <div>{t(child.label)}</div>,
      key: child.key,
    })),
  }));
};
