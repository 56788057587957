import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import './SideMenu.scss';
import { useGetWikiSectionsQuery } from '@api/wiki-sektions';
import { v4 } from 'uuid';
import { Link, useLocation } from 'react-router-dom';
import IconByName from '@components/icon-by-name/IconByName';
import { FileTextOutlined } from '@ant-design/icons';
import { type WikiSectionWithChildren, type WikiSection } from '@models/wiki-section.interface';
import { cloneDeep, filter, isEmpty } from 'lodash';
import { type ArticleShort } from '@models/article.interface';
import { processUrlString } from '@utils/produse-url-string';
import { ARTICLE_PREFIX, ROUTERS, WIKI_PREFIX, WIKI_START_ID } from '@constants/routers.const';
import SideSearch from '@components/side-search/SideSearch';
import { useProductContext } from '@context/product/Product.context';

type MenuItem = Required<MenuProps>['items'][number];

function SideMenu({ collapsed }: { collapsed: boolean }): JSX.Element {
  const { product } = useProductContext();
  const { data, isLoading } = useGetWikiSectionsQuery(product);
  const [menuItems, setMenuItems] = useState<MenuProps['items']>([]);
  const [defaultOpenKeys, setDefaultOpenKeys] = useState<string[]>([]);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([]);
  const { pathname } = useLocation();
  const isElementContainArticle = (item: WikiSection<WikiSectionWithChildren>, article: string): boolean => {
    return item.attributes.articles?.data.some(el => el.attributes.slug === article || el.attributes.name === article);
  };
  const isElementContainWiki = (item: WikiSection<WikiSectionWithChildren>, key: string): boolean => {
    return item.id.toString() === key;
  };
  const getSlugOrArticleName = (): string => {
    if (pathname.startsWith(ARTICLE_PREFIX(product))) {
      return decodeURI(pathname.replace(ARTICLE_PREFIX(product), ''));
    }
    return '';
  };
  const getWikiId = (): string => {
    if (!getSlugOrArticleName() && pathname.startsWith(WIKI_PREFIX(product))) {
      const id = pathname.replace(WIKI_PREFIX(product), '');
      return id !== WIKI_START_ID ? id : '';
    }
    return '';
  };

  const filterItemsBySearch = (
    items: Array<WikiSection<WikiSectionWithChildren>>,
    text: string,
    check: (item: WikiSection<WikiSectionWithChildren>, text: string) => boolean,
  ): Array<WikiSection<WikiSectionWithChildren>> => {
    if (!text) {
      return items;
    } else {
      const clone = cloneDeep(items);
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      const filterTree = (list: Array<WikiSection<WikiSectionWithChildren>>) => {
        return filter(list, item => {
          if (item.attributes.childs?.data?.length) {
            item.attributes.childs.data = filterTree(item.attributes.childs.data) as Array<
              WikiSection<WikiSectionWithChildren>
            >;
          }
          if (check(item, text)) {
            return true;
          } else if (item.attributes.childs?.data?.length) {
            return !isEmpty(item.attributes.childs.data);
          }
        });
      };
      return filterTree(clone) as Array<WikiSection<WikiSectionWithChildren>>;
    }
  };

  const getMenuArticle = (item: ArticleShort): MenuItem => {
    const key = v4();
    const slugOrName = getSlugOrArticleName();
    if (slugOrName && (slugOrName === item.attributes.slug || slugOrName === item.attributes.name)) {
      setDefaultSelectedKeys([key]);
    }
    return {
      label: (
        <Link to={processUrlString(ROUTERS.article(product), { id: item.attributes.slug })}>
          {item.attributes.title}
        </Link>
      ),
      key,
      icon: <FileTextOutlined />,
    };
  };

  const getMenuItems = (
    items: Array<WikiSection<WikiSectionWithChildren>>,
  ): { items: MenuProps['items']; keys: string[] } => {
    const keys: string[] = [];
    function getMenuItem(item: WikiSection<WikiSectionWithChildren>): MenuItem {
      const key = item.id.toString();
      return {
        label: (
          <Link
            onClick={() => {
              setDefaultSelectedKeys([key]);
            }}
            to={`${WIKI_PREFIX(product)}${key}`}>
            {item.attributes.name}
          </Link>
        ),
        key,
        icon: item.attributes.icon ? <IconByName type={item.attributes.icon} /> : undefined,
        children:
          item.attributes.childs?.data.length || item.attributes.articles.data.length
            ? (item.attributes.childs?.data.map(child => getMenuItem(child)) ?? []).concat(
                [...item.attributes.articles.data]
                  .sort((a, b) => a.attributes.order - b.attributes.order)
                  .map(article => getMenuArticle(article)),
              )
            : undefined,
      };
    }

    const menuData = items.map(i => getMenuItem(i));
    return {
      items: menuData,
      keys,
    };
  };

  useEffect(() => {
    const info = getMenuItems(data?.data ?? []);
    const slugOrName = getSlugOrArticleName();
    const wikiId = getWikiId();
    if (slugOrName || wikiId) {
      const filteredItems = slugOrName
        ? filterItemsBySearch(data?.data ?? [], slugOrName, isElementContainArticle)
        : filterItemsBySearch(data?.data ?? [], wikiId, isElementContainWiki);
      const keys: string[] = [];
      const collectTreeNodesKey = (items: Array<WikiSection<WikiSectionWithChildren>>): void => {
        items.forEach(item => {
          keys.push(item.id.toString());
          if (item.attributes?.childs?.data.length) {
            collectTreeNodesKey(item.attributes.childs.data);
          }
        });
      };
      collectTreeNodesKey(filteredItems);
      setDefaultOpenKeys([...defaultOpenKeys, ...keys]);
      if (wikiId) {
        setDefaultSelectedKeys([...keys]);
      }
    }
    if (!defaultOpenKeys.length) {
      setDefaultOpenKeys(info.items?.map(item => item?.key?.toString() ?? '') ?? []);
    }
    setMenuItems(info.items);
  }, [data, pathname]);

  return !isLoading && menuItems ? (
    <div className='side-menu'>
      {!collapsed && <SideSearch />}
      <Menu
        items={menuItems}
        mode='inline'
        openKeys={defaultOpenKeys}
        selectedKeys={defaultSelectedKeys}
        onOpenChange={openKeys => {
          setDefaultOpenKeys(openKeys);
        }}
        onSelect={selected => {
          setDefaultSelectedKeys([selected.key]);
        }}
      />
    </div>
  ) : (
    <></>
  );
}

export default SideMenu;
