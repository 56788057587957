import React from 'react';
import { from } from 'rxjs';
import './CopyCopyButton.scss';
import { Button } from 'antd';

export default function CodeCopyBtn({ children }: { children: any }): JSX.Element {
  const [copyOk, setCopyOk] = React.useState(false);
  const iconColor = copyOk ? '#0af20a' : '#ddd';
  const handleClick = (): void => {
    from(navigator.clipboard.writeText(children[0].props.children[0])).subscribe(() => {
      setCopyOk(true);
      setTimeout(() => {
        setCopyOk(false);
      }, 500);
    });
  };
  return (
    <Button type='text' className='code-copy-btn' onClick={handleClick} style={{ color: iconColor }}>
      {copyOk ? 'Copied!' : 'Copy'}
    </Button>
  );
}
