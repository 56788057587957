import IconByName from '@components/icon-by-name/IconByName';
import { ROUTERS } from '@constants/routers.const';
import React from 'react';
import { Link } from 'react-router-dom';
import './ProductsListCart.scss';
import type { Product } from '@models/product.interface';
import { useTranslation } from 'react-i18next';

function ProductsListCart({ product }: { product: Product }): JSX.Element {
  const { t } = useTranslation();
  return (
    <Link to={ROUTERS.product(product.path)} className='product-cart flex flex-column'>
      <div className='product-cart__title-area flex align-center'>
        <div className='product-cart__icon'>
          <IconByName type={product.icon} />
        </div>

        <div className='product-cart__title'>{t(product.title)}</div>
      </div>
      <div className='product-cart__description'>{t(product.description)}</div>
    </Link>
  );
}

export default ProductsListCart;
