import React from 'react';

function LegalFile({ fileUrl }: { fileUrl: string }): JSX.Element {
  return (
    <iframe
      style={{ height: '100%' }}
      src={`${process.env.REACT_APP_IMAGE_URL ?? ''}${fileUrl}`}
      allow='autoplay; fullscreen'
    />
  );
}

export default LegalFile;
