import React from 'react';
import './BlogBreadcrumbs.scss';
import { Link } from 'react-router-dom';
import { ROUTERS } from '@constants/routers.const';
import { useTranslation } from 'react-i18next';
import { type BlogCategory } from '@models/blog-categories.interface';

function BlogBreadcrumbs({ blogTitle, category }: { category: BlogCategory | null; blogTitle: string }): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className='flex breadcrumbs'>
      <Link className='breadcrumbs__link' to={ROUTERS.blog}>
        {t('COMPONENT.NAVBAR.BLOG')}
      </Link>
      {category && (
        <>
          <img src='/images/icons/arrow-right.svg' alt='arrow-icon' />
          <Link className='breadcrumbs__link' to={`${ROUTERS.blog}/?category=${category.id}`}>
            {category.attributes.name}
          </Link>
        </>
      )}
      <>
        <img src='/images/icons/arrow-right.svg' alt='arrow-icon' />
        <div className='breadcrumbs__text'>{blogTitle}</div>
      </>
    </div>
  );
}

export default BlogBreadcrumbs;
