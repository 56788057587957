export const ARTICLE_PREFIX_OLD = '/knowledgeBase/article/';
export const WIKI_PREFIX_OLD = '/knowledgeBase/articles/';
export const ARTICLE_PREFIX = (product: string): string => `/knowledgeBase/${product}/article/`;
export const WIKI_PREFIX = (product: string): string => `/knowledgeBase/${product}/articles/`;
export const LEGAL_PREFIX = '/legal/';

export const ROUTERS = {
  home: '/',
  blog: '/blog',
  blogArticle: '/blog/:id',
  article: (product: string) => `${ARTICLE_PREFIX(product)}:id`,
  articles: (product: string) => `${WIKI_PREFIX(product)}:id`,
  articleOld: `${ARTICLE_PREFIX_OLD}:id`,
  articlesOld: `${WIKI_PREFIX_OLD}:id`,
  product: (product: string) => `/knowledgeBase/${product}`,
  searchOld: '/knowledgeBase/search/',
  search: (product: string) => `/knowledgeBase/${product}/search/`,
  knowledgeBase: '/knowledgeBase',
  contacts: '/contacts',
  storage: '/products/storage',
  archive: '/products/archive',
  clients: '/clients',
  partners: '/partners',
  legal: '/legal',
  legalFile: '/legal/:id',
};

export const WIKI_START_ID = 'start';
export const WIKI_SEARCH_ID = 'search';
