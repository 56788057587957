import CodeCopyBtn from '@components/copy-code-button/CopyCodeButton';
import React from 'react';
import './PreCodeArea.scss';

export default function PreCodeArea({ children }: { children: any }): JSX.Element {
  return (
    <pre className='pre-code-area'>
      <CodeCopyBtn>{children}</CodeCopyBtn>
      {children}
    </pre>
  );
}
