import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tokenHeaderSetter } from '@utils/token-header-setter';
import {
  type WikiSectionExtendedAttributes,
  type WikiSection,
  type WikiSectionWithChildren,
} from '@models/wiki-section.interface';
import { type DataMultiple } from '@models/data-multiple.interface';
import { type Data } from '@models/data.interface';
import { modifyArticleLink } from '@utils/markdown/modify-article-link';
import { modifyArticleImage } from '@utils/markdown/modify-article-image';
import { upperFirst } from 'lodash';

export const wikiSectionsApi = createApi({
  reducerPath: 'wikiSectionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: tokenHeaderSetter,
  }),
  endpoints: builder => ({
    getWikiSections: builder.query<DataMultiple<WikiSection<WikiSectionExtendedAttributes>>, string>({
      query: product =>
        `wiki-sections/?populate=articles,product,childs.product,childs.childs.product,articles.product,childs.articles.product,childs.childs.articles.product,childs.articles,childs.childs.articles,childs.childs.childs.articles,childs.childs.childs&filters[parent]&sort[0]=order&sort[1]=name&filters[product][slug][$eq]=${product}`,
      transformResponse: (response: DataMultiple<WikiSection<WikiSectionExtendedAttributes>>) => {
        function sortChildren(items: WikiSection<WikiSectionWithChildren>): void {
          if (items.attributes.articles.data.length) {
            items.attributes.articles.data = items.attributes.articles.data.filter(
              article =>
                article.attributes.product?.data &&
                article.attributes.product?.data.attributes.slug ===
                  response.data[0].attributes.product?.data.attributes.slug,
            );
          }
          if (items.attributes.childs?.data.length) {
            items.attributes.childs.data = items.attributes.childs.data
              .filter(
                section =>
                  section.attributes.product?.data &&
                  section.attributes.product?.data.attributes.slug ===
                    response.data[0].attributes.product?.data.attributes.slug,
              )
              .sort((first, second) => first.attributes.order - second.attributes.order);
            items.attributes.childs.data.forEach(el => {
              sortChildren(el);
            });
          }
        }
        response.data.forEach(i => {
          sortChildren(i);
        });
        return response;
      },
    }),
    getWikiSectionById: builder.query<Data<WikiSection>, string>({
      query: (id: string) => `wiki-sections/${id}`,
      transformResponse: (response: Data<WikiSection>) => {
        let content = response.data.attributes.content;
        content = modifyArticleLink(content);
        const resultWithImage = modifyArticleImage(content);
        content = resultWithImage.content;
        Object.assign(response.data.attributes, { images: resultWithImage.images });
        response.data.attributes.content = content;
        return response;
      },
    }),
    getWikiSectionBySearch: builder.query<
      DataMultiple<WikiSection<WikiSectionExtendedAttributes>>,
      { search: string; isShort: boolean; product: string }
    >({
      query: ({ search, isShort, product }) =>
        `wiki-sections/?filters[$or][0][name][$containsi]=${search}&filters[$or][1][name][$containsi]=${upperFirst(search)}&sort[0]=order&sort[1]=name&populate=parent,parent.parent${isShort ? '&pagination[start]=0&pagination[limit]=5' : ''}&filters[product][slug][$eq]=${product}`,
    }),
  }),
});

export const { useGetWikiSectionsQuery, useGetWikiSectionByIdQuery, useGetWikiSectionBySearchQuery } = wikiSectionsApi;
