// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.router-error-page {
  height: calc(100vh - 111px);
}

@media (max-width: 1439px) {
  .router-error-page {
    height: calc(100vh - 95px);
  }
}
@media (max-width: 1023px) {
  .router-error-page {
    height: calc(100vh - 65px);
  }
}`, "",{"version":3,"sources":["webpack://./src/containers/router-error-page/RouterErrorPage.scss"],"names":[],"mappings":"AAGA;EACE,2BAAA;AAFF;;AAKA;EACE;IACE,0BAAA;EAFF;AACF;AAKA;EACE;IACE,0BAAA;EAHF;AACF","sourcesContent":["@import '@styles/colors.scss';\n@import '@styles/constants.scss';\n\n.router-error-page {\n  height: calc(100vh - $navbar-height);\n}\n\n@media (max-width: $break-point) {\n  .router-error-page {\n    height: calc(100vh - $small-navbar-height);\n  }\n}\n\n@media (max-width: $mobile-break-point) {\n  .router-error-page {\n    height: calc(100vh - $mobile-navbar-height);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
