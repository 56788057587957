import React, { useEffect } from 'react';
import './Home.scss';
import { useViewMode } from '@hooks/use-window-size';
import HomeMainInfo from '@components/home-main-info/HomeMainInfo';
import { VIEW } from '@constants/layout.constants';
import HomeEcosystems from '@components/home-ecosystem/HomeEcosystem';
import HomeTasks from '@components/home-tasks/HomeTasks';
import HomeFooter from '@components/home-footer/HomeFooter';
import { useTranslation } from 'react-i18next';
// import HomeReviews from '@components/home-reviews/HomeReviews';

function Home(): JSX.Element {
  const { t } = useTranslation();
  const view = useViewMode();

  useEffect(() => {
    document.title = t('GENERAL.TITLE');
  }, []);

  return (
    <div className='flex flex-column slow'>
      <div className={`home flex flex-column ${view === VIEW.PC ? 'home__content' : ''}`}>
        <HomeMainInfo view={view} />
        <HomeEcosystems view={view} />
        {/* <HomeReviews /> */}
      </div>
      <HomeTasks />
      <div className='home__footer'>
        <HomeFooter view={view} />
      </div>
    </div>
  );
}

export default Home;
