import { BREAK_POINT, VIEW } from '@constants/layout.constants';
import { useLayoutEffect, useState } from 'react';

export function useViewMode(): VIEW {
  const [view, setView] = useState(VIEW.PC);
  useLayoutEffect(() => {
    function updateSize(): void {
      if (window.innerWidth > BREAK_POINT.PC) {
        setView(VIEW.PC);
      } else if (window.innerWidth > BREAK_POINT.TABLET) {
        setView(VIEW.TABLET);
      } else {
        setView(VIEW.MOBILE);
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);
  return view;
}
