import { type SubscriptionData } from '@models/blog-subscription.interface';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { openNotification } from '@utils/open-notification';
import { t } from 'i18next';

export const blogSubscriptionApi = createApi({
  reducerPath: 'blogSubscriptionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BLOG_SUBSCRIPTION_URL,
  }),
  endpoints: builder => ({
    sendBlogSubscription: builder.mutation<unknown, SubscriptionData>({
      query: (data: SubscriptionData) => ({
        url: '',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      async onQueryStarted(_arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
          openNotification('success', t('CONTAINERS.HOME.CONTACT.MESSAGES.SUCCESS'));
        } catch (err) {
          openNotification('error', t('CONTAINERS.HOME.CONTACT.MESSAGES.ERROR'));
        }
      },
    }),
  }),
});

export const { useSendBlogSubscriptionMutation } = blogSubscriptionApi;
