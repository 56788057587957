import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tokenHeaderSetter } from '@utils/token-header-setter';
import { type BlogPage } from '@models/blog-page.interface';
import { type Data } from '@models/data.interface';

export const blogPageApi = createApi({
  reducerPath: 'blogPageApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: tokenHeaderSetter,
  }),
  endpoints: builder => ({
    getBlogPage: builder.query<Data<BlogPage>, string>({
      query: () => 'blog-page/?populate=*,main_article.blog_category,main_article.cover',
    }),
  }),
});

export const { useGetBlogPageQuery } = blogPageApi;
