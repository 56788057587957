// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article-version-selector {
  gap: 4px;
}
.article-version-selector__selector {
  height: 50px;
}
.article-version-selector .ant-select-selector {
  border-radius: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/article-version-selector/ArticleVectorSelector.scss"],"names":[],"mappings":"AAAA;EACE,QAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,mBAAA;AAAJ","sourcesContent":[".article-version-selector {\n  gap: 4px;\n\n  &__selector {\n    height: 50px;\n  }\n\n  .ant-select-selector {\n    border-radius: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
